import React, { useState } from 'react'
// import cross from '../../../assets/images/cros.svg'
import cross from '../../../assets/images/gray-btnchange.svg'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearOfferingData } from '../../../Counter/offeringSLice'

function DraftSidebar({ submitForm, setModalDraft,linkNavigate}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader,setLoader] = useState(false)
  const submitData = async()=>{
    setLoader(true)
    await submitForm()
    setLoader(false)
  }
  

  return (
   <>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="ready-publish">
                <button className="close-button" onClick={() => setModalDraft(false)}>
                  <img src={cross} alt="" />
                </button>
                <h3>Do you want to save those changes?</h3>
                {/* <p></p> */}
                <div className="bottom-button">
                  <button
                    className="draft"
                    onClick={() => {dispatch(clearOfferingData()); navigate(linkNavigate);setModalDraft(false)}}
                  >
                   Cancel
                  </button>
                  <button
                    className="publish"
                    onClick={() => submitData()}
                    disabled={loader}
                  >
                    {loader && <div className="spinner-border" role="status"><span className="sr-only"></span></div>}
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
     </>
  )
}

export default DraftSidebar