import React, { useEffect, useState } from 'react'
import bannerRight from '../../../assets/images/banner-right1.png'
import share from '../../../assets/images/share.svg'
import heart from '../../../assets/images/heart2.svg'
import clock from '../../../assets/images/clock.svg'
import calendar from '../../../assets/images/calendar.svg'
import people from '../../../assets/images/people.svg'
import mapPin from '../../../assets/images/map-pin.svg'
import productImg from '../../../assets/images/product-img.png'
import wellness from '../../../assets/images/wellness.svg'
import arrow from '../../../assets/images/arrow.svg'
import Slider from 'react-slick';
import yoga_boy from '../../../assets/images/yoga-boy.png'
// import yoga_boy2 from '../../../assets/images/yoga-boy2.svg'
import Main_foto from '../../../assets/images/Main_foto.png'
import yoga_girl from '../../../assets/images/yoga-girl.png'
import yoga_girl2 from '../../../assets/images/yoga-girl2.png'
import yoga_group from '../../../assets/images/yoga-group.png'
import yoga_hero from '../../../assets/images/yoga-hero.svg'
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
// import leftArrow from '../../../assets/images/left-arrow_black.svg'
import { insertOfferingData } from '../../../Counter/offeringSLice'
import { useDispatch } from 'react-redux'
import OfferingDetails from './OfferingDetails'
import Modal from 'react-modal';
import cross from '../../../assets/images/cros.svg'
import LiveModal from './liveModal'
import PublishModal from './publishModal'
import VIewOfferingDetails from './viewOfferingDetails'
// import heart from '../../../assets/images/heart 2v.svg'
import leftArrow from '../../../assets/images/backarrow.svg'
import dummyProfile from '../../../assets/images/profile3.svg'
import DraftModal from './draftModal'

function OfferingViewData({ nextStep, prevStep, step, data, submitForm,adminType }) {
  const dispatch = useDispatch();
  const [classState, setClassState] = useState(false)
  const [readyPublish, setReadyPublish] = useState(false)
  const [offeringLive, setOfferingLive] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false);
  const [stateModal, setStateModal] = useState(false)
  const [isModal, setIsModal] = useState(false)

  useEffect(() => {
    const handleClick = () => setClassState(false);

    document.addEventListener('click', handleClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])
  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };
  // const data = data

  const handleSave = async() => {
    // const values = {
    //   showData: ''
    // }
    // dispatch(insertOfferingData(values))
    // prevStep('viewData')
    setStateModal(true)
    const resp = await submitForm('draft', data)
    if (resp === true) {
      setStateModal(false)
      setIsModal(true)
    } else {
      setStateModal(false)
    }
  }

  const userData = JSON.parse(localStorage.getItem('full_user_detail'));

  const handlePublish = (type) => {
    if (type === 'ready') {
      setReadyPublish(true)
    }
    if (type === 'live') {
      setReadyPublish(false)
      setOfferingLive(true)
    }
  }

  const closeModal = (type) => {
    if (type === 'ready') {
      setReadyPublish(false)
    }
    if (type === 'live') {
      setOfferingLive(false)
    }
  }
  const toggleText = () => {
    setIsExpanded(!isExpanded);
};

// Ensure description is defined and is a string
const description = data?.description || '';
const isLongDescription = description.length > 300;
const truncatedDescription = description.length > 300 ? `${description.slice(0, 300)}...` : description;
  

  return (
    <div className='offering-view'>
      <UserSidebar classState={classState} />
        <div className='right-main'>
      {/* <UserSidebar classState={classState} /> */}
      <div className='discover-offering'>
        <div className='header'>
        <div class="active-links">
          <button className="back" onClick={() => prevStep()}><img src={leftArrow} alt='' /> </button>
          <h2><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>{data?.title}</h2>
        </div>
        <div className='save-publish'>
        <button className='save-draft' onClick={()=>handleSave('draft')} disabled={stateModal}>{stateModal && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save to Draft</button>
        <button className='publish' onClick={() => handlePublish('ready')}>Publish</button>
        </div>
        </div>
        {/* <div className='right-main'> */}
        <section class="creators-top view-single-offer">
          
            <div class="row">
              <div class="col-sm-6 col-xl-8 col-lg-6">
                <div class="offering-left">
                  {/* <h3><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>{data?.name}</h3> */}
                  {/* <label>Yoga</label>
                  <label>Fitness</label>
                  <label>Meditation</label> */}
                  {data?.tagName && data?.tagName?.length > 0 && data?.tagName.map((x,index) =>{
                  return(
                  <label>{x}</label>
                  )}
                  )}

                  <div class="annete">
                    <span><img src={userData?.user_profile_image || dummyProfile} alt='' /></span>
                    <div class="annete-text">
                      <h3>{userData?.name}</h3>
                      <h4>{userData?.user?.specializations[0]?.area_of_work}</h4>
                      <h5>2 years of experience</h5>
                      {/* <h4>{userData?.specializations && userData?.specializations.length > 0 && userData?.specializations[0]?.area_of_work}</h4>
                      <h5>{userData?.specializations && userData?.specializations.length > 0 && userData?.specializations[0]?.experience}</h5> */}
                    </div>
                  </div>

                  <div class="about-left">
                    <h3>About Class</h3>
                    <p>
                      {/* {data?.description}  */}
                      {isExpanded || !isLongDescription ? description : truncatedDescription}
                      {/* Welcome to our morning yoga session! Get ready to kickstart your day with a blend of gentle stretches, mindful breathing, and positive vibes. I'll guide you through a series of poses designed to awaken your body and uplift your spirit. Whether you're a seasoned yogi or a beginner, this class is for everyone! Let's flow together and embrace the beauty of each breath. See you on the mat! */}
                      <br />
                      {/* <strong>Read Less</strong> */}
                      <strong onClick={toggleText} style={{ cursor: 'pointer' }}>
                                                {description.length > 300 && (isExpanded ? 'Read less' : 'Read more')}
                                            </strong>
                    </p>
                  </div>

                </div>
         

              {/* <div class="col-sm-4">
              <div class="offering-top-right">
                <h2>Morning Yoga</h2>
                <div class="offering-share">
                  <ul>
                    <li><a href=""><img src={share} alt=''/></a></li>
                    <li><a href=""><img src={heart} alt=''/></a></li>
                  </ul>
                </div>
                <div class="week">
                  <ul>
                    <li><img src={clock} alt='' /> 60 min</li>
                    <li><img src={calendar} alt=''/> 2x per week, 12 weeks</li>
                  </ul>
                </div>
                <div class="week">
                  <ul>
                    <li><img src={people} alt=''/> 8-12 people</li>
                    <li><img src={mapPin} alt='' /> Broome St, New York</li>
                  </ul>
                </div>
                <div class="time">
                  <h3>April 6(GMT+)</h3>
                  <button>12:00pm</button>
                  <button>03:00pm</button>
                </div>
                <div class="time">
                  <h3>April 12(GMT+)</h3>
                  <button>12:00pm</button>
                  <button>04:00pm</button>
                  <button>06:00pm</button>
                </div>
                <div class="offering-right-bottom">
                  <h4>Price <strong>$25</strong></h4>
                  <button>Proceed to checkout</button>
                </div>
              </div>
            </div> */}
                </div>
              <VIewOfferingDetails data={data} />
              <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12">
                  <div class="row">
                  <div class="col-lg-9 col-sm-9">
                    <div class="main-image">
                    {data?.showImagePreview && data?.showImagePreview.startsWith("data:image/") || data?.cover_image != ''?
                      <img src={data?.showImagePreview || data?.cover_image} alt='' />
                    : 
                    <video controls><source src={data?.showImagePreview} type='video/mp4' /></video>
                    }                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3">
                    <div className='jewellery-img-height'>
                    <div className="row jw-cs">

                    {data?.additionalImages?.length>0 && data?.additionalImages.map((x,index)=>{
                      return(
                        // index>0 &&
                        <div className="col-6 col-lg-12 col-sm-12 " key={index}>
                        {x?.startsWith("data:image/") ?
                        <div className="jewellery-img">

                      <img src={x} alt="" />
                      </div>
                      :
                      <div className="jewellery-img">
                     
                      <video controls><source src={x} type='video/mp4' /></video>
                      </div>

                        }
                    </div>
                      )
                    })}  

                    </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>
             
        
          
        </section>

      </div>

      <Modal
        isOpen={readyPublish}
        onRequestClose={() => closeModal('ready')}
        contentLabel="Publish Modal"
        shouldCloseOnOverlayClick={false}
        portalClassName="creatorLiveModal publish-cross-set"
      >
        {/* <button className='close-button' onClick={() => closeModal('ready')}><img src={cross} alt='' /></button> */}
        <PublishModal submitForm={submitForm} handlePublish={handlePublish} closeModal={closeModal} type={''} setIsModal={setIsModal}/>

        {/* <h3>Ready To Publish</h3>
        <h4>Once you publish your first product, your offer will go live. People will be able to see the offering page tab and purchase your products</h4>
        <button onClick={()=> submitForm('draft')}>Save To Draft</button>
        <button onClick={() => handlePublish('live')}>Publish</button> */}
      </Modal>
      <Modal
        isOpen={offeringLive}
        onRequestClose={() => closeModal('live')}
        contentLabel="Publish Modal"
        shouldCloseOnOverlayClick={false}
        portalClassName="creatorPublishModal"
      >
        {/* <button className='close-button' onClick={() => closeModal('live')}><img src={cross} alt='' /></button> */}
        {/* <h3>Your offering is live</h3>
        <button onClick={()=>submitForm('publish')}>Done</button> */}
        <LiveModal submitForm={submitForm} closeModal={closeModal}  type={''} imagePreview={''} adminType={adminType} />
      </Modal>
      </div>
      <DraftModal setIsModal={setIsModal} isModal={isModal} steps={'final'}/>
      {/* </div> */}
    </div>
  )
}

export default OfferingViewData