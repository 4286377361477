import React, { useEffect, useState, useCallback } from 'react';
import UserSidebar from '../../../Layout/userSidebar';
import burger from '../../../assets/images/burger-menu.svg';
import { getApi, postApi } from '../../../Services/apiService'
import search from '../../../assets/images/search.svg'
import hearts from '../../../assets/images/heart.svg'
import heart_filled_black from '../../../assets/images/heart_filled_black.svg'
import { useNavigate } from 'react-router-dom'
import bannerRight from '../../../assets/images/dummy-profile.jpg'
import location from '../../../assets/images/map-pin1.svg'
import favNoData from '../../../assets/images/favNoData.svg'


function UserFavourite() {
    const [offeringData, setOfferingData] = useState([])
    const [classState, setClassState] = useState(false)
    const [loadData, setLoadData] = useState(6);
    const [selectedTagIds, setSelectedTagIds] = useState([]); // New state for selected tag IDs
    const [selectedCategoryId, setSelectedCategoryId] = useState(null); // Single selected category ID
    const [selectedLocationTypes, setSelectedLocationTypes] = useState([]);
    const [searchText, setSearchText] = useState(''); // New state for search text
    const [creatorText, setCreatorSearchText] = useState(''); // New state for search text
    const [shouldFetch, setShouldFetch] = useState(true); // State to trigger fetch based on filters
    const [showSearchResults, setShowSearchResults] = useState(false); // New state for managing search results
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [, setRender] = useState(false);
    const [pageLoader, setPageLoader] = useState(false)


    const navigate = useNavigate();




    const singleService = (id, x) => {
        if (x.service_type === '2') {
            navigate(`/user/favourites/product-details/${id}`)
        }
        else {
            navigate(`/user/favourites/offering-details/${id}`)
        }
    }





    const handleLocationTypeChange = (typeId) => {
        setSelectedLocationTypes(prevState => {
            return prevState.includes(typeId)
                ? prevState.filter(id => id !== typeId)
                : [...prevState, typeId];
        });
        setShouldFetch(true); // Trigger fetch when location type changes
    };

    //   / Function to handle loading more offerings
    const loadMoreOfferings = () => {
        setPage((prevPage) => prevPage + 1); // Increment the page number
    };
    const handleFetchOffering = async () => {
        const tagIdsParam = selectedTagIds.join(',');
        const categoryIdParam = selectedCategoryId || '';
        const locationTypeIdsParam = selectedLocationTypes.join(',');
        const searchParam = searchText ? `&search=${encodeURIComponent(searchText)}` : '';

        if (tagIdsParam.length || categoryIdParam !== '' || locationTypeIdsParam.length || searchParam !== '') {
            setShowSearchResults(true); // Show search results section
        }
        else {
            setShowSearchResults(false); // Remove search results section
        }
        try {
            setPageLoader(true)
            // const response = await getApi(`/favorite?page=${}&pageSize=${9}&location_type=${locationTypeIdsParam}${searchParam}`);
            const response = await getApi(`/favorite?page=${page}&pageSize=${9}&location_type=${locationTypeIdsParam}`);
            if (response?.statusCode === 200) {
                setTotalCount(response.totalCount); // Set the total count from API response
                if (page === 1) {
                    setOfferingData(response.data)
                }
                else {
                    setOfferingData((prevOfferings) => [...prevOfferings, ...response.data]);
                }
            }
            setPageLoader(false)
        } catch (error) {
            console.error('Error fetching offerings:', error);
        }
    };



    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
    const handleCreatorSearchChange = (e) => {
        setCreatorSearchText(e.target.value);
    };

    useEffect(() => {
        handleFetchOffering();
        const handleClick = () => setClassState(false);
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [page, selectedLocationTypes, searchText]);



    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };

    const handleFavourite = async (val) => {
        const data = {
            status: val?.isFavorite === 0 ? '1' : '0', // Conditional check to toggle the favorite status
            offerId: val?.id
        };
        try {
            // Optimistically toggle isFavorite
            val.isFavorite = val.isFavorite === 0 ? 1 : 0;

            // Trigger a re-render to reflect the change in UI
            setRender(prev => !prev);
            const response = await postApi('favorite', data);
            if (response?.statusCode === 200) {
                // handleFetchOffering();
            }
        } catch (error) {
            console.error('Error fetching offerings:', error);
        }
    }



    return (
        <div className="creators-detail offering-user-side">
            <UserSidebar classState={classState} />
            <div className='creators right-main min-height-pera fav'>
                <section className="favourt-cs">
                    <div className="">
                        <h1><button className="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>Favourites</h1>

                        {/* <div className="creators-input">
                            <img src={search} alt='' />
                            <input
                                type="text"
                                className="search"
                                placeholder="Search offer"
                                value={searchText}
                                onChange={handleSearchChange}
                            />
                        </div> */}
                    </div>

                </section>

                {pageLoader ?
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    :
                    <>
                        {offeringData && (offeringData.length > 0 || selectedLocationTypes.length > 0) && (
                            <div className="what-are-you">
                                <p>What are you looking for</p>
                                <ul>
                                    <li>
                                        <label className="container2">
                                            Virtual Session
                                            <input
                                                type="checkbox"
                                                checked={selectedLocationTypes.includes(1)}
                                                onChange={() => handleLocationTypeChange(1)}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container2">
                                            In Person Session
                                            <input
                                                type="checkbox"
                                                checked={selectedLocationTypes.includes(2)}
                                                onChange={() => handleLocationTypeChange(2)}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container2">
                                            Physical product
                                            <input
                                                type="checkbox"
                                                checked={selectedLocationTypes.includes(3)}
                                                onChange={() => handleLocationTypeChange(3)}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="container2">
                                            Digital product
                                            <input
                                                type="checkbox"
                                                checked={selectedLocationTypes.includes(4)}
                                                onChange={() => handleLocationTypeChange(4)}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        )}


                        {offeringData.length ? (
                            <>
                                {showSearchResults ? (
                                    <section className="other-class-main">

                                        <h3>Search Results</h3>
                                        {/* <OfferingData data={offeringData} type='offering' /> */}

                                        <div className="row">
                                            {offeringData && offeringData.length > 0 && offeringData.map((x, index) => {
                                                return (
                                                    (index < loadData) &&
                                                    <div className="col-sm-4"  >
                                                        <div className="product-box">
                                                            <div className="product-img" onClick={() => singleService(x?.offering?.id, x?.offering)} key={index}>
                                                                <img src={x?.offering?.cover_image} alt='' />
                                                                <div className="product-image-data">
                                                                    <span><img src={x?.offering?.category && x?.offering?.category?.category_image} alt='' /></span>
                                                                    <h5>
                                                                        {x?.offering?.service_type === "2"
                                                                            ? (x?.offering?.product_kind === "0" ? 'Physical' : 'Digital')
                                                                            : (x?.offering?.service_type === "1"
                                                                                ? (x?.offering?.location_type === "1" ? 'Online'
                                                                                    : (x?.offering?.location_type === "2" ? 'Offline'
                                                                                        : (x?.offering?.location_type === "3" ? 'Hybrid' : '')))
                                                                                : '')}
                                                                    </h5>

                                                                    <h6><img src={location} alt='' />Austin, Texas</h6>
                                                                </div>
                                                            </div>
                                                            {/* <button className="heart-btn" onClick={() => { handleFavourite(x?.offering) }}>
                                                            <img src={x?.offering?.isFavorite === 1 ? heart_filled_black : hearts} alt='' />
                                                            </button> */}
                                                            <button
                                                                className={`heart-btn ${x?.offering?.isFavorite === 1 ? "heart-filled" : "heart-outline"}`}
                                                                onClick={() => handleFavourite(x?.offering)}
                                                            >
                                                                <img src={x?.offering?.isFavorite === 1 ? heart_filled_black : hearts} alt='' />
                                                            </button>

                                                            <div className="product-data" onClick={() => singleService(x?.offering?.id, x?.offering)} key={index}>
                                                                <h3>{x?.offering?.name} </h3>
                                                                <ul>
                                                                    {x?.offering?.tags && x?.offering?.tags.length > 0 && x?.offering?.tags.slice(0, 3).map((y, i) => {
                                                                        return (
                                                                            <li className={i === 0 && "active"}>{y?.tag_name}</li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                                <p>
                                                                    {x?.offering?.description}
                                                                </p>
                                                                <div className="product-data-bottom">
                                                                    <span><img src={x?.offering?.user?.user_profile_image && x?.offering?.user?.user_profile_image || bannerRight} alt='' /></span>
                                                                    <h6><strong>{x?.offering?.user && x?.offering?.user?.name}</strong> {x?.offering?.user?.specializations && x?.offering?.user?.specializations[0]?.area_of_work}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {/* {type === 'offering' && */}
                                        {offeringData.length > 0 && offeringData.length < totalCount && (
                                            <button className="explore" onClick={loadMoreOfferings}>
                                                Load more Offerings
                                            </button>
                                        )}

                                    </section>
                                ) : (
                                    <>
                                        <section className="other-class-main">
                                            {/* <OfferingData data={offeringData} type='offering' /> */}

                                            <div className="row">
                                                {offeringData && offeringData.length > 0 && offeringData.map((x, index) => {
                                                    return (
                                                        (index < loadData) &&
                                                        <div className="col-sm-4"  >
                                                            <div className="product-box">
                                                                <div className="product-img" onClick={() => singleService(x?.offering?.id, x?.offering)} key={index}>
                                                                    <img src={x?.offering?.cover_image} alt='' />
                                                                    <div className="product-image-data" >
                                                                        <span><img src={x?.offering?.category && x?.offering?.category?.category_image} alt='' /></span>
                                                                        <h5>
                                                                            {x?.offering?.service_type === "2"
                                                                                ? (x?.offering?.product_kind === "0" ? 'Physical' : 'Digital')
                                                                                : (x?.offering?.service_type === "1"
                                                                                    ? (x?.offering?.location_type === "1" ? 'Online'
                                                                                        : (x?.offering?.location_type === "2" ? 'Offline'
                                                                                            : (x?.offering?.location_type === "3" ? 'Hybrid' : '')))
                                                                                    : '')}
                                                                        </h5>
                                                                        <h6><img src={location} alt='' />Austin, Texas</h6>
                                                                    </div>
                                                                </div>
                                                                {/* <button className="heart-btn" onClick={() => { handleFavourite(x?.offering) }}>
                                                                    <img src={x?.offering?.isFavorite === 1 ? heart_filled_black : hearts} alt='' />
                                                                </button> */}
                                                                <button
                                                                    className={`heart-btn ${x?.offering?.isFavorite === 1 ? "heart-filled" : "heart-outline"}`}
                                                                    onClick={() => handleFavourite(x?.offering)}
                                                                >
                                                                    <img src={x?.offering?.isFavorite === 1 ? heart_filled_black : hearts} alt='' />
                                                                </button>

                                                                <div className="product-data" onClick={() => singleService(x?.offering?.id, x?.offering)} key={index}>
                                                                    <h3>{x?.offering?.name} </h3>
                                                                    <ul>
                                                                        {x?.offering?.tags && x?.offering?.tags.length > 0 && x?.offering?.tags.slice(0, 3).map((y, i) => {
                                                                            return (
                                                                                <li className={i === 0 && "active"}>{y?.tag_name}</li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                    <p>
                                                                        {x?.offering?.description}
                                                                    </p>
                                                                    <div className="product-data-bottom">
                                                                        <span><img src={x?.offering?.user?.user_profile_image && x?.offering?.user?.user_profile_image || bannerRight} alt='' /></span>
                                                                        <h6><strong>{x?.offering?.user && x?.offering?.user?.name}</strong> {x?.offering?.user?.specializations && x?.offering?.user?.specializations[0]?.area_of_work}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            {/* {type === 'offering' && */}
                                            {offeringData.length > 0 && offeringData.length < totalCount && (
                                                <button className="explore" onClick={loadMoreOfferings}>
                                                    Load more Offerings
                                                </button>
                                            )}
                                        </section>
                                    </>
                                )
                                }
                            </>
                        ) : (
                            <>
                                <div className='fav-div-sec'>
                                    <div className=''>
                                        <div className='mb-3'>
                                            <img src={favNoData} alt='' />
                                        </div>
                                        <p>Your favorites list is currently empty. However, you can visit the <span className='fw-bold'> Discovery </span>section to find offers that match your interests and add them here!</p>
                                    </div>
                                </div>
                            </>
                        )
                        }
                    </>
                }
            </div>

        </div>
    )
}

export default UserFavourite;