import React, { useState } from 'react'
// import { getApi } from '../../Services/apiService';
// import { Link } from 'react-router-dom';
import scrollDown from '../../assets/images/scroll-down.svg'
import bannerRight from '../../assets/images/banner-right.png'
import about from '../../assets/images/about-img.png'
import shareImg from '../../assets/images/share-img.png'
import UniqueOffering from './uniqueOffering';
import ServiceOffering from './serviceOffering';
import Modal from 'react-modal';
import MultiStep from '../CreatorComponents/CreatorJoin/multiStep';
import cross from '../../assets/images/cross-button.svg'
import { Link } from 'react-router-dom'

function Home() {
  //  const getDataValue = async () => {
  //   const getData = await getApi('/get_all_service');
  //   console.log("getData>>>>>>>", getData);
  //  }
  const [creatorModalIsOpen, setCreatorModalIsOpen] = useState(false);
  const openModal = (value) => {
		if (value === 'creator'){
			setCreatorModalIsOpen(true);
    }
	};

	const closeModal = (value) => {
		{
			if (value === 'creator')
				setCreatorModalIsOpen(false);
		}
	};

  return (

    <div className='container '>
      <section class="banner ">
        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              <div class="banner-left">
                <h2>Be  Abundant  as  you</h2>
                <p>Do what you love for a living. Join our community first marketplace to help scale your
                  business</p>
                  {/* <Link to='/creator/login'> */}
                  <Link to='/creator/login'><button>Join as a creator</button></Link>
                  {/* <button onClick={() => openModal('creator')}>Join as a creator</button> */}
                  {/* </Link> */}
                <span><img src={scrollDown} alt=''/>Scroll Down</span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="banner-right">
                <div class="banner-right-img">
                  <img src={bannerRight} alt=''/>
                  <div class="img-text">
                    <p>Mari Hush is fusing her loves of music, writing, and dancing</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="about">
        <div class="container">
          <div class="row">
            <div class="col-sm-7">
              <div class="about-left">
              <h3 className='mobile-view'>ABOUT MICROCOSM</h3>
                <img src={about} alt=''/>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="about-right">
                <h3 className='web-view'>ABOUT MICROCOSM</h3>
                <p>Know your truth, do what you love a living and be abundant as you. The microcosm
                  community is a safe space for healing, learning and creativity where the journeys of
                  others on a similar path intersect. The marketplace gives you everything you need to
                  host your offering and share it with the world.</p>
                <button>Become a creator</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="discover homepage-ui-discover">
        <div class="container">
          <h3>Discover Unique Offerings</h3>
          <UniqueOffering/>
        </div>
      </section>

      <ServiceOffering/>

      <div class="share">
        <div class="container">
          <div class="row">
            <div class="col-sm-7">
            <h1 className='mobile-view'>SHARE YOUR GIFT WITH THE WORLD</h1>
              <div class="share-img">
                <img src={shareImg} alt=''/>
              </div>
            </div>

            <div class="col-sm-5">
              <div class="share-right">
                <h1 className='web-view'>SHARE YOUR GIFT WITH THE WORLD</h1>
                <p>Your offering is an expression of your truth, its unique and derives from your experience of
                  life. Share your offering and allow someone’s present to be transformed by it.</p>
                <button class="button">Create your offering</button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <Modal
				isOpen={creatorModalIsOpen}
				onRequestClose={() => closeModal('creator')}
				contentLabel="Login Modal"
				shouldCloseOnOverlayClick={false}
			>
				<button className='close-button' onClick={() => closeModal('creator')}><img src={cross} alt=''/></button>
				<MultiStep creatorModalIsOpen={creatorModalIsOpen} setCreatorModalIsOpen={setCreatorModalIsOpen} />
			</Modal>

    </div>
  );
}

export default Home