import { getApi } from "../Services/apiService";

const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };
//   export const handleAddressChange = debounce(async (value) => {
  export const handleAddressChanges = async (value) => {
    const resp = await getApi(`/places/autocomplete?search=${value}`);
    if (resp?.statusCode === 200) {
        return {resp}
    //   setGetAddressData(resp?.data);
    }
  };

  export const convertSlotTo12HourFormatAmPm = (slot,duration) => {
    const [date, time] = slot.split(', '); // Split the date and time
    let [hours, minutes] = time.split(':').map(Number); // Convert to numbers
  
    // Format the original time
    let hours12 = hours % 12 || 12; 
    const ampm = hours >= 12 ? 'pm' : 'am';
    const originalTime = `${hours12}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  
    // Add 60 minutes to the original time
    const newDate = new Date(0, 0, 0, hours, minutes + Number(duration)); // Create a Date object
    let newHours = newDate.getHours();
    let newMinutes = newDate.getMinutes();
    const newAmpm = newHours >= 12 ? 'pm' : 'am';
    newHours = newHours % 12 || 12; 
    const newTime = `${newHours}:${newMinutes.toString().padStart(2, '0')} ${newAmpm}`;
  
    return `${originalTime} - ${newTime}`;
  };

  export const formatDateWeekSlot = (dateString) => {
    // Convert DD/MM/YYYY to MM/DD/YYYY
    const [day, month, year] = dateString.split('/');
    const validDateString = `${month}/${day}/${year}`;
  
    const date = new Date(validDateString); // Now it's valid
    const options = { weekday: 'short', day: 'numeric', month: 'short' };
  
    const parts = date.toLocaleDateString('en-US', options).split(' ');
  
    return `${parts[0]} ${parts[2]} ${parts[1]}`;
  };