import React, { useState } from 'react'
// import cross from '../../../assets/images/cros.svg'
import cross from '../../../assets/images/gray-btnchange.svg'
import { useNavigate } from 'react-router-dom'

function PublishModal({ submitForm, handlePublish, closeModal, type,setIsModal,offerType,card_connected }) {

  
  const [stateModal, setStateModal] = useState(false)
  const [draftLoader,setDraftLoader] = useState(false)
  const [publishLoader,setPublishLoader] = useState(false)
  const [connectLoader,setConnectLoader] = useState(false)
console.log('this is card connected',card_connected);
const navigate = useNavigate();

const localDataUser = JSON.parse(localStorage.getItem('full_user_detail'));
console.log('this is localDataUser----------',localDataUser);

  const handleApi = async(types) =>{
    setStateModal(true)
    // if(type === 'product'){

    // } else{
      let resp;
    if(types === 'draft'){
      setDraftLoader(true)
       resp = await submitForm('draft')
       setDraftLoader(false)
       if(resp === true){
        setStateModal(false)
        // handlePublish('live')
        setIsModal(true)
      } else{
        setStateModal(false)
      }
    }
    else{
      setPublishLoader(true)
      resp = await submitForm('publish')
      setPublishLoader(false)
      if(resp === true){
        setStateModal(false)
        handlePublish('live')
      } else{
        setStateModal(false)
      }
    } 
  // }
  }

  const connectCard = async() => {
    setConnectLoader(true)
    await submitForm('draft')
    setConnectLoader(false)
    const card = false
    navigate(`/creator/settings/card`);
  }
  
  return (
   <>
    {localDataUser?.is_connected === true ? (
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="ready-publish">
                <button className="close-button" onClick={() => closeModal('ready')}>
                  <img src={cross} alt="" />
                </button>
                <h3>Ready To Publish?</h3>
                {offerType === 'service' ? (
                  <p>Once you publish your first service, your offer will go live. People will be able to see the offering page tab.</p>
                ) : (
                  <p>Once you publish your first product, your offer will go live. People will be able to see the offering page tab and purchase your products.</p>
                )}
                <div className="bottom-button">
                  <button
                    className="draft"
                    onClick={() => handleApi('draft')}
                    disabled={stateModal && (draftLoader || publishLoader)}
                  >
                    {stateModal && draftLoader && <div className="spinner-border" role="status"><span className="sr-only"></span></div>}
                    Save To Draft
                  </button>
                  <button
                    className="publish"
                    onClick={() => handleApi()}
                    disabled={stateModal && (draftLoader || publishLoader)}
                  >
                    {stateModal && publishLoader && <div className="spinner-border" role="status"><span className="sr-only"></span></div>}
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
       ) : (
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="ready-publish">
                <button className="close-button" onClick={() => closeModal('ready')}>
                  <img src={cross} alt="" />
                </button>
                <h3>Card Not Connected</h3>
                <p>Your payout method is not connected. Please connect a method to receive payments and proceed with publishing. You can save the offer for now by clicking 'Save to Draft' and publish it later once your payout method is connected.</p>
                <div className="bottom-button">
                  <button
                    className="draft"
                    onClick={() => handleApi('draft')}
                    disabled={stateModal && draftLoader}
                  >
                    {stateModal && draftLoader && <div className="spinner-border" role="status"><span className="sr-only"></span></div>}
                    Save To Draft
                  </button>
                  <button
                    className="publish"
                    onClick={() => connectCard()}
                    disabled={connectLoader}
                  >
                    Connect Card
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
     </>
  )
}

export default PublishModal