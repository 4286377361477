import React, { useEffect, useState, useCallback } from 'react';
import UserSidebar from '../../../Layout/userSidebar';
import burger from '../../../assets/images/burger-menu.svg';
import addtag from '../../../assets/images/add-tag.svg'
import search from '../../../assets/images/search.svg'
import bannerRight from '../../../assets/images/dummy-profile.jpg'
import hearts from '../../../assets/images/heart.svg'
import clender from '../../../assets/images/clender.svg'
import clock from '../../../assets/images/clock2.svg'
import usergreen from '../../../assets/images/user-green.svg'
import threedots from '../../../assets/images/three-dots-horizontal.svg'
import { getApi } from '../../../Services/apiService';
import share from '../../../assets/images/share-2.svg'
import attendus from '../../../assets/images/view-attendus.svg'
import cancel from '../../../assets/images/cross-tick-image.svg'
import AttendeeList from './attendeeList';
import cross from '../../../assets/images/gray-close5.svg'
import emptydata from '../../../assets/images/empty-data.svg'
import Modal from 'react-modal';

function SessionList() {
    const [classState, setClassState] = useState(false);
    const [sessionData, setSessionData] = useState([])
    const [pageSize, setPageSize] = useState(9)
    const [page, setPage] = useState(1)
    const [typeSession, setTypeSession] = useState('current')
    const [searchValue, setSearchValue] = useState('')
    const [weekDates, setWeekDates] = useState([]);
    const [activeDropdown, setActiveDropdown] = useState(false);
    const [activeDropdownId, setActiveDropdownId] = useState('');
    const [attendeeData, setAttendeeData] = useState([]);
    const [attendeeModal, setAttendeeModal] = useState(false);
    const [singleOfferingData,setSingleOfferingData] = useState({})
    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };


    useEffect(() => {
        const datesArray = sessionData && sessionData.length > 0 && sessionData.map((offer) => ({
            date: offer?.serviceSlot?.date,
            day: getDayName(offer?.serviceSlot?.date),
        }))
            .filter(
                (value, index, self) =>
                    index === self.findIndex((item) => item.date === value.date)
            );
        setWeekDates(datesArray);
        // setWeekDates(getCurrentWeekDates());
        const handleClick = () => setClassState(false);
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [sessionData]);
    useEffect(() => {
        handleFetchSession()
    }, [page, pageSize, searchValue, typeSession])

    const getDayName = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date);
    };

    const getCurrentWeekDates = () => {
        const today = new Date();
        const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1)); // Monday

        const week = [];
        const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        for (let i = 0; i < 7; i++) {
            const date = new Date(startOfWeek);
            date.setDate(startOfWeek.getDate() + i);

            week.push({
                date: date.toISOString().split('T')[0], // Format: YYYY-MM-DD
                day: dayNames[i], // Get day name from the array
            });
        }
        return week;
    };

    const handleFetchSession = async () => {
        const dates = new Date();
        const year = dates.getFullYear();
        const month = String(dates.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(dates.getDate()).padStart(2, '0');

        const customDate = `${year}-${month}-${day}`;
        const response = await getApi(`/creator/service/upcoming?page=${page}&pageSize=${pageSize}&search=${searchValue}&date=${customDate}&type=${typeSession}`)
        if (response?.statusCode === 200) {
            setSessionData(response?.data)
        }
    }

    const handleViewAttendee = async (id,x) => {
        setSingleOfferingData(x)
        const response = await getApi(`/creator/service/user?offerServiceId=${id}`)
        if (response?.statusCode === 200) {
            setAttendeeData(response?.data)
            setActiveDropdown(false)
            setAttendeeModal(true)
        }
    }

    return (
        <div>
            <div className="HomeMainDiv">
                <UserSidebar classState={classState} />
                <div className="creator-offering home min-height-pera sessions">
                    <div className="RightSideDiv right-main">
                        <div className='header'>
                            <button className="burger-menu" onClick={handleSidebarClick}>
                                <img src={burger} alt='burger menu' />
                            </button>
                            <h2>My Sessions</h2>
                        </div>
                        <div className='top-buttons'>
                            <div className='input-container'>
                                <img src={search} alt="Add tag" />
                                <input
                                    type="text"
                                    placeholder='Find Offerings or Creators'
                                    onChange={(e) => setSearchValue(e?.target?.value)}
                                />
                            </div>
                        </div>
                        <div className='top-buttons justify-content-end'>
                            <div class="creator-offering-tab"
                            >  <ul>
                                    <li onClick={() => setTypeSession('current')}><a className={typeSession === 'current' && 'active'} >
                                        Upcoming
                                    </a> </li>
                                    <li onClick={() => setTypeSession('past')}><a className={typeSession === 'past' && 'active'} >
                                        Past
                                    </a> </li>
                                </ul>
                            </div>

                        </div>
                        <div>
                           
                            {weekDates && weekDates?.length > 0 ?
                            <div className="data-scroll">

                                {weekDates && weekDates?.length > 0 && weekDates.map((weeks, i) => {
                                    return (
                                        <>
                                            <h3>{weeks?.day + ' ' + weeks?.date}</h3>


                                            <div className="row">

                                                {sessionData && sessionData?.length > 0 && sessionData.map((x, index) => {
                                                    return (
                                                        weeks?.date == x?.serviceSlot?.date &&
                                                        <div className="col-sm-4" >
                                                            <div className="product-box">
                                                                <div className="product-img">
                                                                    <img src={x?.cover_image} alt='' />
                                                                    <div className="product-image-data">
                                                                        <span><img src={x?.category?.category_image} alt='' /></span>
                                                                        <h5>
                                                                            Physical
                                                                        </h5>
                                                                        <button><img src={hearts} alt='' /></button>
                                                                    </div>
                                                                </div>
                                                                <div className="product-data" onClick={() => { setActiveDropdownId(''); setActiveDropdown(false) }}>
                                                                    <h3>{x?.title} </h3>
                                                                    <ul className='product-data-tags'>
                                                                        {x?.tags && x?.tags?.length > 0 && x?.tags.map((y, ind) => {
                                                                            return (
                                                                                <li className={''}>
                                                                                    {y?.tag_name}
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                    <p>{x?.description}</p>
                                                                    <div className='col-md-10'>
                                                                        <div className='date-&-time d-flex w-100 pt-2 justify-content-between'>
                                                                            <div className='clender d-flex align-items-center'>
                                                                                <span ><img src={clender} alt='box' className='pe-1' />{x?.serviceSlot?.date}</span>
                                                                                {/* <span ><img src={clender} alt='box' className='pe-1' />{new Date(x?.serviceSlot?.date).toLocaleDateString('en-CA')}</span> */}
                                                                            </div>
                                                                            <div className='clender d-flex align-items-center'>
                                                                                <span className='ms-2'><img src={clock} alt='box' className='pe-1' />{x?.serviceSlot?.start_time}</span>
                                                                            </div>
                                                                            <div className='clender d-flex align-items-center'>
                                                                                <span className='ms-2'><img src={usergreen} alt='box' className='pe-1' />{x?.bookedSlotsCount}</span>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                    <div className="product-data-bottom">
                                                                        <span><img src={x?.user?.user_profile_image} alt='' /></span>
                                                                        <h6><strong>{x?.user?.name}</strong> </h6>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-1 text-end action-pointer-cs' onClick={() => { setActiveDropdownId(index); setActiveDropdown(true) }}>
                                                                    <span className='action'><img src={threedots} alt='action' /></span>
                                                                </div>
                                                                {activeDropdown && activeDropdownId === index && (
                                                                    <div className='arcived-list'>
                                                                        <ul>
                                                                            <li >
                                                                                <img src={share} disabled={''} alt='box' />Share

                                                                            </li>
                                                                            <li onClick={() => handleViewAttendee(x?.id,x)}>
                                                                                <img src={attendus} alt='box' />
                                                                                View Attendees
                                                                            </li>
                                                                            <li>
                                                                                <img src={cancel} alt='box' />
                                                                                Cancel
                                                                            </li>

                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                })}




                                            </div>

                                        </>
                                    )
                                })}
                            </div>
                            :
                            <div className='fav-div-sec'>
                            <div className=''>
                                <div className='mb-3'>
                                    <img src={emptydata} alt='' />
                                </div>
                                <p>Nothing here yet</p>
                            </div>
                        </div>
}
                        </div>
                        {/* <button className="explore">
                            Load more Offerings
                        </button> */}
                    </div>
                </div>
            </div>
            <Modal
                isOpen={attendeeModal}
                onRequestClose={() => setAttendeeModal(false)}
                contentLabel="Availbility Modal"
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
                portalClassName={`address-modal address-modal-diff-cs view-attendent  select-creator`}
            >
                <button className='close-button ' onClick={() => setAttendeeModal(false)}><img src={cross} alt='' /></button>
                <AttendeeList attendeeData={attendeeData} userData={singleOfferingData}/>

            </Modal>
        </div>
    );
}
export default SessionList;
