import React, { useEffect, useState,useRef } from 'react'
import logo_black from '../assets/images/Logo_black.svg'
import home1 from '../assets/images/home-1.svg'
import search_custom from '../assets/images/search custom.svg'
import journey from '../assets/images/journey.svg'
import heart_2v from '../assets/images/heart 2v.svg'
import creator_icon from '../assets/images/creator icon.svg'
import message from '../assets/images/message.svg'
import notification from '../assets/images/notification.svg'
import settings from '../assets/images/settings.svg'
import dotted_btn from '../assets/images/dotted-btn.svg'
import { getApi, postApi } from '../Services/apiService'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import logout from '../assets/images/logout_icon 1.svg'
import dummy from '../assets/images/dummy-profile.jpg'
import member from '../assets/images/members.svg'
import offerings from '../assets/images/offering.svg'
import requests from '../assets/images/request.svg'
import insight from '../assets/images/insights.svg'
import bookings from '../assets/images/bookings.svg'
import orders from '../assets/images/orders.svg'
import setAvailability from '../assets/images/availability.svg'
import offering from '../assets/images/offering (1).svg'
import insightSidebar from '../assets/images/insightSidebar.svg'
import session from '../assets/images/sessionSidebar.svg'
import order from '../assets/images/orderimg.svg'
import logouticon from '../assets/images/logout.svg'
import Modal from 'react-modal';
import cross from '../assets/images/gray-close5.svg'
import unauthorized from '../assets/images/unauthorizedUsed.png'
import dropDown from '../assets/images/drop-arrow.svg'
import { useDispatch, useSelector } from 'react-redux'
import { clearOfferingData } from '../Counter/offeringSLice'
import DraftSidebar from '../Component/CreatorComponents/CreatorOffering/draftSidebar'


function UserSidebar({ classState, name }) {
  const [loader, setLoader] = useState(false)
  const [logoutModal, setLogoutModal] = useState(false);
  const [unAuthorizedModal, setunAuthorizedModal] = useState(false);
  const [userDetails, setUserDetails] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false); // New state to handle dropdown
  const [modalDraft,setModalDraft] = useState(false)
  const[linkNavigate,setLinkNavigate] = useState('')
  const navigate = useNavigate()
  const dropdownRef = useRef(null); // Ref for the dropdown
  const dispatch = useDispatch()
  const formState = useSelector((state) => state.offerCounter);


  const location = useLocation(); // Get the current location
  const handleLogout = () => {
    localStorage.clear();
    navigate('/')
  }
  // const userRole = name?.roles?.length>0 && name?.roles[0]?.user_role
  const closeModal = (type) => {
    setLogoutModal(false);
    setunAuthorizedModal(false);

  };
  const openModal = (id) => {
    setLogoutModal(true);
  };
  const localDataUser = JSON.parse(localStorage.getItem('userDetail'));
  let userRole = (localStorage.getItem('user_role'));
  // console.log('this is userdata**********', userRole);
  const userDatass = JSON.parse(localStorage.getItem('full_user_detail'));
  const isUser = localDataUser?.roles.some(role => role.user_role === "user");  
  // console.log('this is admin details------',localDataUser);
  // userRole = userRole || null
  // const userRole = localDataUser?.roles?.length>0 && localDataUser?.roles[0]?.user_role;
  const isActive = (path) => location.pathname.startsWith(path);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen); // Toggle dropdown visibility
  };
 
  // Close dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false); // Close dropdown
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

      
  useEffect(() => {
    const currentUrl = window.location.href;
    if(userRole==='creator'){
      fetchDetails();
    }
  }, [userRole])

  const fetchDetails = async () => {
    const response = await getApi(`/creator/dashboard`)
    if (response?.statusCode === 200) {
      setUserDetails(response?.data)
    }
  }


  function handleClick(e, path) {
    // console.log('this is onboarding detail',userDetails);
    // console.log('this is onboarding detail',userRole);
    if (userDetails?.onboarding_interview === 0) {
      e.preventDefault(); // Prevent navigation
      setunAuthorizedModal(true)
      // alert('You are not a verified user.');
    } else {
      handleSaveOfferingData()
      navigate(path); // Allow navigation
    }
  }
  const areObjectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    return keys1.every(key => obj1[key] === obj2[key]);
  };

  const submitDraftData = async() =>{
    const storeOfferData = {
    value: 0,
    isAuthenticated: false,
    creator_id: undefined
}

    
      // await SubmitForm('draft',formState)
      const getValue = (key) => formState?.[key];
      const getLocationType = (classMode) => ({ online: 1, in_person: 2, hybrid: 3 }[classMode] || 0);
      const getSessionOfferType = (sessionType) => ({ single_session: 1, multiple_session: 2 }[sessionType] || 0);
      const getSessionType = (sessionTypes) => ({ private_session: 1, group_session: 2 }[sessionTypes] || 0);
      const getTimeType = (specifyTime) => ({ exact: 1, availability: 2 }[specifyTime] || 0);
      const getPackageType = (sessionMultipleType) => ({ weekly: 1, monthly: 2, custom: 3, daily: 4 }[sessionMultipleType] || 0);
      const getSessionRepeat = (sessionRepeat) => ({ one: 1, daily: 2, weekly: 3, monthly: 4, weekday: 5, weekend: 6 }[sessionRepeat] || 0);
      const getPricingType = (discoveryPrice) => ({ fixed_price: 1, variable_price: 2 }[discoveryPrice] || 0);
      const getAvailabilityPeriod = (availabilityPeriod) => ({ indefinitely: 1, date: 2 }[availabilityPeriod] || 0);
      const getDiscovery = (discoveryValue) => ({ true: '1', false: '0' }[discoveryValue] || '0');

    let locationType = getLocationType(getValue('classMode'));
    let session_offer_type = getSessionOfferType(getValue('sessionType'));
    let session_type = getSessionType(getValue('sessionTypes'));
    let time_type = getTimeType(getValue('specifyTime'));
    let package_type = getPackageType(getValue('sessionMultipleType'));
    let session_repeat = getSessionRepeat(getValue('sessionRepeat'));
    let pricing_type = getPricingType(getValue('discoveryPrice'));
    let availability_period = getAvailabilityPeriod(getValue('availabilityPeriod'));
    let discoveryValue = getDiscovery(getValue('discoveryCall'));

    let formData = new FormData();
    formData.append('name', getValue('name'));
    formData.append('description', getValue('description'));
    formData.append('category_id', getValue('categoryId'));
    formData.append('offer_tag', getValue('tags'));
    formData.append('offer_type', getValue('type'));
    formData.append('address_id', getValue('address'));
    formData.append('location_type', locationType);
    formData.append('invitation_link', getValue('invitationLink'));
    formData.append('session_occurrences', JSON.stringify(getValue('recurrences') || getValue('recurrencess')) || null);
    formData.append('session_type', session_type);
    formData.append('min_group_size', getValue('minGroupSize') || 0);
    formData.append('max_group_size', getValue('maxGroupSize'));
    formData.append('pricing_type', pricing_type);
    formData.append('price', getValue('discoveryMinPrice'));
    formData.append('minimum_price', getValue('discoveryMinPrice'));
    formData.append('maximum_price', getValue('discoveryMaxPrice'));
    formData.append('session_offer_type', session_offer_type);
    formData.append('time_type', 2);
    formData.append('package_type', package_type);
    formData.append('session_repeat', session_repeat);
    formData.append('cover_image', getValue('coverImage'));
    formData.append('stepvalue',  Number(formState?.step) - 1);
    formData.append('travel_area', getValue('travelArea'));
    formData.append('travel_charge_fee', getValue('chargeTravel'));
    formData.append('in_person_option', 3);
    formData.append('service_type', 1);
    formData.append('location', getValue('locationInput'));
    formData.append('message_to_user', getValue('messageUser'));
    formData.append('offer_status', 'draft');
    formData.append('no_of_sessions', Number(getValue('sessionPackage')) || 0);
    formData.append('package_timeframe', getValue('timeFrame')?.number);
    formData.append('package_period', getValue('timeFrame')?.months);
    formData.append('title', getValue('title'));
    formData.append('deleted_ids', getValue('removedImageIds'));
    formData.append('draft_same_time', getValue('sameTime'));
    formData.append('draft_same_duration', JSON.stringify(getValue('sameTimeDuratioon')));
    formData.append('draft_times', JSON.stringify(getValue('selectedDayLabel')));
    formData.append('draft_slot', JSON.stringify(getValue('addDatesCalendar')));
    formData.append('availbility_period', availability_period);
    formData.append('discovery_call', discoveryValue);

    const additionalImages = getValue('additionalImagesPreview');
    if (Array.isArray(additionalImages)) {
      additionalImages.forEach((image) => {
        if (image instanceof File || image instanceof Blob) {
          formData.append('materials', image);
        }
      });
    }
    if(!areObjectsEqual(formState, storeOfferData)){
      
    let response;
      formData.append('id', getValue('id'));
      response = await postApi('/creator/draft', formData);
      if(response?.statusCode === 200){
      dispatch(clearOfferingData())
      }
    }
    // setLinkNavigate('')
    navigate(linkNavigate)

    return true;

  }

  const handleSaveOfferingData = async(type)=>{
    setLinkNavigate(type)
    const storeOfferData = {
      value: 0,
      isAuthenticated: false,
      creator_id: undefined
  }
  
    if(!areObjectsEqual(formState, storeOfferData)){
    setModalDraft(true)
    }else{
      navigate(type)
    }

  }



  return (
    <div className={`creators-detail-left ${classState === true && 'responsiveNav'}`}>
      {/* <div class="creators-detail-left {}"> */}
      <div className="home-logo">
        <img loading="lazy" alt='' src={logo_black} />
      </div>
     
        {userRole === 'creator' &&
          <>
           <ul className='creator-sidebar'>
           {/* <Link to='/creator/home'> */}
            <li className={isActive('/creator/home') || isActive('/creator/dashboard') ? 'active' : ''} onClick={()=>handleSaveOfferingData('/creator/home')}><span><img loading="lazy" alt='' src={home1} />Home</span></li>
            {/* </Link> */}
             <Link to='/creator/offering/home'>
             <li className={isActive('/creator/offering/home') || isActive('/creator/offering') ? 'active' : ''} onClick={(e) => handleClick(e, '/creator/offering/home')}><span><img loading="lazy" alt='' src={offering} />Offerings</span></li>
             </Link>
            {/* <Link to={'/creator/session'}> */}
            <li className={isActive('/creator/session') ? 'active' : ''} onClick={()=>handleSaveOfferingData('/creator/session')}><span><img loading="lazy" alt='' src={session} />Session</span></li>
            {/* </Link> */}
            {/* <Link to='/creator/orders'> */}
            <li className={isActive('/creator/orders') ? 'active' : ''} onClick={()=>handleSaveOfferingData('/creator/orders')}><span><img loading="lazy" alt='' src={order} />Orders</span></li>
            {/* </Link> */}
            {/* <Link to='/creator/messages'> */}
            <li className={isActive('/creator/messages') ? 'active' : ''} onClick={()=>handleSaveOfferingData('/creator/messages')}><span><img loading="lazy" alt='' src={message} />Messages</span></li>
            {/* </Link> */}
            {isUser &&
            <>
            <div className='diff-sidebar-list'>
            {/* <Link to='/user/offering'> */}
            <li className={isActive('/user/offering') ? 'active ' : ''} onClick={()=>handleSaveOfferingData('/user/offering')}><span><img loading="lazy" alt='' src={search_custom} />Discovery</span></li>
            {/* </Link> */}
            {/* <Link to='/user/creators'> */}
            <li className={isActive('/user/creators') ? 'active' : ''} onClick={()=>handleSaveOfferingData('/user/creators')}><span><img loading="lazy" alt='' src={creator_icon} />Creators</span></li>
            {/* </Link> */}
            {/* <Link to='/user/journey'> */}
            <li className={isActive('/user/journey') ? 'active' : ''} onClick={()=>handleSaveOfferingData('/user/journey')}><span><img loading="lazy" alt='' src={journey} />My Journey</span></li>
            {/* </Link> */}
            {/* <Link to='/user/favourites'> */}
            <li className={isActive('/user/favourites') ? 'active' : ''} onClick={()=>handleSaveOfferingData('/user/favourites')}><span><img loading="lazy" alt='' src={heart_2v} />Favourites</span></li>
            {/* </Link> */}
            </div>
            </>
}
            {/* <Link> */}
            <li className={isActive('/creator/insights') ? 'active' : ''} onClick={()=>handleSaveOfferingData()}><span><img loading="lazy" alt='' src={insightSidebar} />Insights</span></li>
            {/* </Link> */}
            {/* <Link to='/creator/notification'> */}
            <li className={isActive('/creator/notification') ? 'active' : ''} onClick={()=>handleSaveOfferingData('/creator/notification')}><span><img loading="lazy" alt='' src={notification} />Notifications</span></li>
            {/* </Link> */}
            {/* <Link to='/creator/settings'> */}
            <li className={isActive('/creator/settings') ? 'active' : ''} onClick={()=>handleSaveOfferingData('/creator/settings')}> <span><img loading="lazy" alt='' src={settings} />Settings</span></li>
            {/* </Link> */}
            {/* <li onClick={() => openModal()}><span><img loading="lazy" alt='' src={logout} />Logout</span></li> */}
          </ul>
          </>
          
        }
       
       
        {userRole === 'user' &&
          <>
          <ul>
            <Link to='/user/home'><li className={isActive('/user/home') ? 'active' : ''}><span><img loading="lazy" alt='' src={home1} /><a>Home</a></span></li></Link>
            {/* <li className={isActive('/user/dashboard') ? 'active' : ''}><Link to='/user/dashboard'><span><img loading="lazy" alt='' src={search_custom} /><a>Discovery</a></span></li></Link> */}
            <Link to='/user/offering'><li className={isActive('/user/offering') ? 'active' : ''}><span><img loading="lazy" alt='' src={offering} />Discovery</span></li></Link>
            <Link to='/user/journey'><li className={isActive('/user/journey') ? 'active' : ''}><span><img loading="lazy" alt='' src={journey} /><a>My Journey</a></span></li></Link>
            <Link to='/user/favourites'><li className={isActive('/user/favourites') ? 'active' : ''}><span><img loading="lazy" alt='' src={heart_2v} /><a>Favourites</a></span></li></Link>
            <Link to='/user/creators'><li className={isActive('/user/creators') ? 'active' : ''}><span><img loading="lazy" alt='' src={creator_icon} /><a>Creators</a></span></li></Link>
            <Link to='/user/messages'><li className={isActive('/user/messages') ? 'active' : ''}><span><img loading="lazy" alt='' src={message} /><a>Messages</a></span></li></Link>
            <Link to='/user/notifications'><li className={isActive('/user/notifications') ? 'active' : ''}><span><img loading="lazy" alt='' src={notification} /><a>Notifications</a></span></li></Link>
            <Link to='/user/settings'><li className={isActive('/user/settings') ? 'active' : ''}><span><img loading="lazy" alt='' src={settings} /><a>Settings</a></span></li></Link>
            {/* <li onClick={() => openModal()}><Link to=''><span><img loading="lazy" alt='' src={logout} />Logout</span></li></Link> */}
         
            </ul>       
            </>
        }
        
        {userRole === 'admin' &&
          <>
          <ul>
            <Link to='/admin/members'><li className={isActive('/admin/members') ? 'active' : ''}><span><img loading="lazy" alt='' src={member} /><a>Members</a></span></li></Link>
            <Link to='/admin/offering'><li className={isActive('/admin/offering') ? 'active' : ''}><span><img loading="lazy" alt='' src={offerings} />Offerings</span></li></Link>
            <Link to='/admin/request'><li className={isActive('/admin/request') ? 'active' : ''}><span><img loading="lazy" alt='' src={requests} /><a>Requests</a></span></li></Link>
            <Link to=''><li className={isActive('/admin/insights') ? 'active' : ''}><span><img loading="lazy" alt='' src={insight} /><a>Insights</a></span></li></Link>
            <Link to='/admin/booking'><li className={isActive('/admin/booking') ? 'active' : ''}><span><img loading="lazy" alt='' src={bookings} /><a>Booking</a></span></li></Link>
            <Link to='/admin/orders'><li className={isActive('/admin/orders') ? 'active' : ''}><span><img loading="lazy" alt='' src={orders} /><a>Orders</a></span></li></Link>
            <Link to='/admin/availability'><li className={isActive('/admin/availability') ? 'active' : ''}><span><img loading="lazy" alt='' src={setAvailability} /><a>Set Availability</a></span></li></Link>
            <Link to='/admin/notification'><li className={isActive('/admin/notification') ? 'active' : ''}><span><img loading="lazy" alt='' src={notification} /><a>Notifications</a></span></li></Link>
            <Link to='/admin/settings'><li className={isActive('/admin/settings') ? 'active' : ''}><span><img loading="lazy" alt='' src={settings} /><a>Settings</a></span></li></Link>
            {/* <li onClick={() => openModal()}><Link to=''><span><img loading="lazy" alt='' src={logout} />Logout</span></Link></li> */}
            </ul>
          </>
        }
    
      {/* <Link to='/'><button >Logout</button></Link> */}
      <div class="user-left-profile" onClick={(e)=>e.stopPropagation()}>
        <span><img loading="lazy" alt='' src={userDatass?.user_profile_image || localDataUser?.user_profile_image || dummy} /> </span>
        <div class="user-content">
          <h1>{localDataUser?.name}</h1>
          <p>{userRole?.charAt(0)?.toUpperCase() + userRole?.slice(1)?.toLowerCase()}</p>
        </div>
        <button className="button" onClick={toggleDropdown}>
          <img loading="lazy" alt='' src={dotted_btn} /> </button>
        {dropdownOpen && (
          <div className="dropdown-menu" ref={dropdownRef}>
          {/* <span className='arrow'><img loading="lazy" src={dropDown} /></span> */}
            <ul>
              <li style={{cursor:'pointer'}} onClick={() => openModal()}><img loading="lazy" alt='' src={logout} />Logout</li>
              {/* <li onClick={() => navigate('/user/settings')}>Settings</li> */}
               
            </ul>
          </div>
        )}
      </div>

      <Modal
        isOpen={logoutModal}
        onRequestClose={closeModal}
        contentLabel="Delete Modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        overlayClassName={`modal-overlay add-category delete logout `} 
        portalClassName="logout-modal-lg">
        
        <span> <img loading="lazy" src={logouticon} /></span>
        <h2>Are you sure you want to logout?</h2>
        <p></p>
        <div className="modal-buttons">
          <button className="cancel-btn" onClick={closeModal}>Cancel</button>
          <button className="save-btn" onClick={handleLogout} disabled={loader}>
            {loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Yes, I'm sure</button>
        </div>
      </Modal>

      <Modal
        isOpen={unAuthorizedModal}
        onRequestClose={closeModal}
        contentLabel="Delete Modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        overlayClassName={`modal-overlay add-category delete logout unauthorize verification-modal`} >
          <button className='close-button' onClick={() => setunAuthorizedModal(false)}><img loading="lazy" src={cross} alt='' /></button>
        <h1>Verification Required</h1>
        <h2>You can’t create a session or product until your specialization is verified by our team. If you've already submitted your request, we'll get back to you soon!</h2>
        <span> <img loading="lazy" src={unauthorized} /></span>
        <p></p>
      </Modal>

      <Modal
                isOpen={modalDraft}
                onRequestClose={() => setModalDraft('false')}
                contentLabel="Publish Modal"
                shouldCloseOnOverlayClick={false}
                portalClassName="creatorLiveModal creatiive-before-publish save-draft-cs"
              >
                <DraftSidebar submitForm={submitDraftData} setModalDraft={setModalDraft} linkNavigate={linkNavigate}/>
              </Modal>

    </div>
  )
}

export default UserSidebar