import React, { useEffect, useState } from 'react'
import { insertOfferingData } from '../../../Counter/offeringSLice'
import { useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { deleteApi, getApi, postApi } from '../../../Services/apiService';
import * as Yup from 'yup';
import { travelAddress } from '../../../Validations/formValidation';
import crossIcon from '../../../assets/images/cros.svg'
import { handleAddressChanges } from '../../../Helper/helperFunction';

function NewAddress({ closeModal, getAddress, setAddressForm,setAddressState,addressState,setClassHappen,classHappen,classMode,data,handleGetAddress }) {
  
  const dispatch = useDispatch();
  const [country, setCountry] = useState('')
  const [state, setState] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [city, setCity] = useState('')
  const [travelArea, setTravelArea] = useState('')
  const [address, setAddress] = useState('')
  const [addressId, setAddressId] = useState(data?.address ||'')
  const [addressIdData, setAddressIdData] = useState(data?.locationInput || '')
  const [addressValidation, setAddressValidation] = useState(false)
  const [currentAddress,setCurrentAddress] = useState('')
  const [getAddressData,setGetAddressData] = useState([])
  const [singleAddressData,setSingleAddressData] = useState([])
  const [isTyping,setIsTyping] = useState(true)
  console.log("addressIdData",getAddressData);
  useEffect(() => {
    if (currentAddress) {
      setAddressId('')
      // handleAddressChange(currentAddress);
      fetchAddressData(); 
    }
  }, [currentAddress]);
  let initialValues = {
    country: addressIdData?.country || '',
    state: addressIdData?.state  || '',
    zipCode: addressIdData?.zip_code || '',
    city: addressIdData?.city || '',
    address: addressIdData?.address || '',
  };
  const handleSave = async (value) => {
    console.log("valuevaluevaluevaluevaluevaluevaluevalue", value);
    const insertValue = {
      country: value?.country || addressIdData?.country,
      state: value?.state || addressIdData?.state,
      zip_code: value?.zipCode || addressIdData?.zip_code,
      city: value?.city || addressIdData?.city,
      travel_area: value?.travelArea || addressIdData?.travelArea,
      address: value?.address || addressIdData?.address
    }
    let response;
    if (!addressValidation) {
      response = await postApi('/address', insertValue)
      // setClassHappen(!classHappen)
      console.log("Addressresponse", response);
    }
    const values = {
      address: response?.data?.id || addressId,
      locationInput: insertValue,
      classMode:classMode
      // address: {
      //   country: value?.country,
      //   state: value?.state,
      //   zipCode: value?.zipCode,
      //   yourCity: value?.city,
      //   travelArea: value?.travelArea,
      //   Address: value?.address
      // }
    }
    setAddressForm(insertValue)

    dispatch(insertOfferingData(values))
    handleGetAddress()
    closeModal()
  }
  // const validationSchema = Yup.object({
  //   country: Yup.string().required('Country is required'),
  //   state: Yup.string().required('State is required'),
  //   zipCode: Yup.string().required('Zip code is required').matches(/^\d{5}$/, 'Invalid zip code'),
  //   city: Yup.string().required('City is required'),
  //   travelArea: Yup.string().required('Travel area is required'),
  //   address: Yup.string().required('Address is required'),
  // });

  const handleAddressSet = (id,e,setFieldValue) => {
    e.stopPropagation()
    setAddressId(id?.id);
    setAddressIdData(id)
    setAddressValidation(true)
    setClassHappen(!classHappen)
    setFieldValue('country', id.country);
    setFieldValue('state', id.state);
    setFieldValue('zipCode', id.zip_code);
    setFieldValue('city', id.city);
    setFieldValue('address', id.address);
    // closeModal()
  }

  const handleDeleteAddress = async(x) =>{
// export const deleteApi = async (url, data) => {
  const values = {
    id:x?.id
  }
  
  const response = await deleteApi(`/address?addressId=${x?.id}`)
  setAddressState(!addressState)
  // if(response.status === 200){
  //   setAddressState(!addressState)
  //   alert('deleted')
  // }
  // else{
  //   alert("not deleted")
  // }
  }
  // const debounce = (func, delay) => {
  //   let timer;
  //   return (...args) => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => func(...args), delay);
  //   };
  // };
  // const handleAddressChange = debounce(async (value) => {
  //   setIsTyping(true)
  //   const resp = await getApi(`/places/autocomplete?search=${value}`);
  //   if (resp?.statusCode === 200) {
  //     setGetAddressData(resp?.data);
  //   }
  // }, 300);
  const fetchAddressData = async () => {
    try {
      setIsTyping(true)
      const res = await handleAddressChanges(currentAddress);
      if (res?.resp?.statusCode === 200) {
        setGetAddressData(res?.resp?.data);
      }
    } catch (error) {
      console.error('Error fetching address data:', error);
    }
};

  return (
    <>
      {/* <!-- Button trigger modal --> */}
      {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#new-address">
        Launch demo modal
      </button> */}

      {/* <!-- Modal --> */}
      {/* <div class="modal fade" id="new-address" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
      <div class="" id="new-address" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            
            <div className="modal-body">
              <div className="new-address">
                <div className="header">
                  <>
                  <h1>New Location</h1>
                  {/* <div className="top-buttons"> */}
                    
                    
                    
                    {/* Save button will call submitForm */}
                    <Formik
                      initialValues={initialValues}
                      validationSchema={addressValidation === false && travelAddress}
                      onSubmit={(value) => handleSave(value)}
                    >
                      {({ submitForm,setFieldValue,values }) => (
                     
                
                        <>
                        <div className='address-btn'>
                        <button className="cancel" onClick={closeModal}>Cancel</button>
                          <button className="save" type="button" onClick={submitForm}>Save</button>
                          </div>
                          <div className="top-buttons top-btns-set">
                          <div className="main">
                            <h2 className="add-new">Add new location</h2>
                            <Form className="row">
                            <div className="col-sm-12 address-change">
                                <label htmlFor="address">*Address</label>
                                <Field type="text" id="address" name="address" placeholder="Street Name and Number, apartment number" className={values.address ? "highlight-field" : ""}
                                onChange={(e) => {
                                  setFieldValue("address", e.target.value);
                                  setCurrentAddress(e.target.value)
                                }}/>
                                 {getAddressData && getAddressData.length > 0 && (
                                <div className='address-find'>
                                 
                <ul className="address-dropdown">
                  {getAddressData.map((x, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setFieldValue("address", x.description);
                        setSingleAddressData(x)
                        setFieldValue("country",x?.country);
                        setFieldValue("city",x?.city);
                        setFieldValue("state",x?.state);
                        setFieldValue("zipCode",x?.zipCode);
                        setGetAddressData([]);

                      }}
                      className="suggestion-item"
                    >
                      {x.description}
                    </li>
                  ))}
                </ul>
              
              </div>
)}
                                <ErrorMessage name="address" component="div" className="error" />
                              </div>
                              <div className="col-sm-6">
                                <label htmlFor="country">Country</label>
                                 <Field type="text" id="country" name="country" className={`daily-weeks-selct ${values?.country ? 'highlighted-field' : ""}`}/>
                                <ErrorMessage name="country" component="div" className="error" />
                              </div>
                              <div className="col-sm-6">
                                <label htmlFor="state">State</label>
                                <Field type="text" id="state" name="state" className={`daily-weeks-selct ${values?.state ? 'highlighted-field' : ""}`}>
                                </Field> 
                                <ErrorMessage name="state" component="div" className="error" />
                              </div>
                              <div className="col-sm-6">
                                <label htmlFor="zipCode">Zip code</label>
                                <Field type="text" id="zipCode" name="zipCode" placeholder="12345689" className={values.zipCode ? 'highlighted-field' : ""}/>
                                <ErrorMessage name="zipCode" component="div" className="error" />
                              </div>
                              <div className="col-sm-6">
                                <label htmlFor="city">Your city</label>
                                <Field type="text" id="city" name="city" className={`daily-weeks-selct ${values?.city ? 'highlighted-field' : ""}`}>
                                </Field>
                                <ErrorMessage name="city" component="div" className="error" />
                              </div>
                              <div className='col-md-12'>
                                <p className='py-3'>*Please note: Your precise address will be visible to users only after purchasing the offer, ensuring safety.</p>
                              </div>
                              {/* <div className="col-sm-12">
                                <label htmlFor="travelArea">*Travel area</label>
                                <Field type="text" id="travelArea" name="travelArea" placeholder="Type your area (Downtown Austin, North Austin, South Austin etc.)" />
                                <ErrorMessage name="travelArea" component="div" className="error" />
                              </div> */}
                            </Form>
                          </div>
                          </div>
                        
                    
                {getAddress && getAddress.length > 0 &&
                <div className="bottom-section">
                  <h3>Saved Location</h3>
                  <div className="row">
                    {getAddress && getAddress.length > 0 && getAddress.map((x, index) => {
                      return (
                        index <3 &&
                        <div className="col-sm-4" key={index} >
                          {/* <div className="address-content" onClick={() => handleAddressSet(x)}> */}
                          <div className="address-content" >
                          {/* <div className="address-content" onClick={(e) => handleAddressSet(x,e)}> */}
                            <span className='close' onClick={()=>handleDeleteAddress(x)}><img src={crossIcon} alt="" /></span>
                            <label className="custom-radio" onClick={(e) => handleAddressSet(x,e,setFieldValue)}>
                              <input type="radio" name="radio" checked={x?.id === addressId}/>
                              <span className="checkmark"></span>
                            </label>
                              <p>Address {index + 1}</p>
                            <h3 className="address-data">
                              {/* 13 Kopengagen Street, Anytown */}
                              {x?.address + ' ' + x?.country}
                              {/* {x?.travel_area + ' ' + x?.address + ' ' + x?.country} */}
                            </h3>
                          </div>
                        </div>
                      )
                    })}
                   
                  </div>
                </div>
}
</>
                        
)}

</Formik>
</>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewAddress