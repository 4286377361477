import React, { useState } from 'react'
import Header from './header'
import leftArrow from '../../../assets/images/left-arrow_black.svg'
import { Link, useNavigate } from 'react-router-dom'
import { insertData } from '../../../Counter/signupSlice'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { userDetailValidation } from '../../../Validations/formValidation'
import eye from '../../../assets/images/Eye.svg'
import eyeClosed from '../../../assets/images/eye-icon-change.svg'
import { postApi } from '../../../Services/apiService'


function SignUpDetail({ nextStep, prevStep, data }) {
  const [showPassword, setShowPassword] = useState(false);
  const [emailStatus,setEmailStatus] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleVerifyEmail = async(e) =>{
    setEmailStatus(false)
    const values = {
      email: e?.target?.value
    }
    // setTimeout(() => {
      if(e?.target?.value !== ''){
      makeApiCall(values);
      }
    // }, 3000);
    
    // if(response?.statusCode === 200){

    // }

  }
  const makeApiCall = async(value)=>{
    const values = {
      email:value?.email,
      user_role:'creator',
    }
    const response = await postApi('/check-email',values)
    
    if(response?.statusCode === 400){
      setEmailStatus(true)
    }
  }

  const initialValues = {
    name: data.name || '',
    email: data.email || '',
    password: data.password || ''
  }

  const dispatch = useDispatch();
  const onSubmit = async (values) => {
    if(emailStatus === false){
    dispatch(insertData(values));
    nextStep()
    }
  }
  const getClassNames = (fieldName,values,errors,touched) => {
    let classNames = "form-control";
    if (values[fieldName]) classNames += " writing"; // Add 'writing' if there's input
    if (errors[fieldName] && touched[fieldName]) classNames += " erroring"; // Add 'erroring' if there’s a validation error and field is touched
    return classNames;
  };
  return (
    <div className='detail-page signup-cs'>
      <Header/>
      <div className="detail-main">
        <h1>Enter your details</h1>
        <p>Add name and password to complete your registration</p>

        <Formik
          initialValues={initialValues}
          validationSchema={userDetailValidation}
          onSubmit={onSubmit}
        >
          
          {({ handleChange, setFieldValue, values, errors, touched ,setFieldTouched}) => (
          <Form>
            {/* <div className="modal-body"> */}
            <div className="form-group">
              <label htmlFor="name" >
                Your Name *
              </label>
              <Field
                type="text"
                id="name"
                name="name"
            className={getClassNames("name",values,errors,touched)}
                // className="form-control"
                placeholder="What should we call you"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" >
                Email *
              </label>
              <Field
                type="text"
                id="email"
                name="email"
                // className="form-control"
            className={getClassNames("email",values,errors,touched)}
                placeholder="Write your Email"
                onChange={(e) => {
                  handleChange(e);
                  handleVerifyEmail(e); 
                }}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger"
              />
              <div className="text-danger">
              {emailStatus && 'Email already exist'}
                </div >
            </div>
            <div className="form-group">
              <label htmlFor="password" >
                Password *
              </label>
              <Field
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                // className="form-control"
                className={getClassNames("password",values,errors,touched)}
                placeholder="Create Password"
              />
               <span className="eye-icon" onClick={togglePasswordVisibility}>
              {showPassword ? <img src={eye} alt=''/> : <img src={eyeClosed} alt=''/>}
              {/* {showPassword ? '🙈' : '👁️'} */}
            </span>
              <ErrorMessage
                name="password"
                component="div"
                className="text-danger"
              />
            </div>
            <button type="submit">
              {/* {/* <button type="submit" className="btn btn-primary">Login</button> */}
              Continue
              {/* <SubmitButton submitting={isLoading} onClick={insertData()}/> */}
            </button>

            {/* </div> */}
          </Form>
          )}
        </Formik>

      </div>
      <div className="back-button">
        <button className="button" onClick={() => prevStep()}><img src={leftArrow} /> </button>
      </div>

    </div>
  )
}

export default SignUpDetail