import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getApi, postApi } from '../../../Services/apiService';
import cross from '../../../assets/images/cross-button.svg';
import check from '../../../assets/images/success.svg';
import remove from '../../../assets/images/close-circle.svg';
import uncheckCircle from '../../../assets/images/uncheck.svg';
import PaymentModal from './payment';
import { convertSlotTo12HourFormatAmPm, formatDateWeekSlot } from '../../../Helper/helperFunction';

function BookingModal({ data, closeModal, setFiltersModal, setDataToPaymentModal,setConfirmLocation }) {
  console.log('this is data from prev', data);
  const initialValues = {
    selectedDate: data?.selectedDate || '',
    selectedTimes: data?.selectedTimes || '',
    price: ''
  };

  const [value, onChange] = useState(new Date());
  const [slotDisplay, setSlotDisplay] = useState([]);
  const [markedDates, setMarkedDates] = useState([]);
  // const [filtersModal, setFiltersModal] = useState(false);
  const [activeStartDate, setActiveStartDate] = useState(new Date()); // State for active start date
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [selectedTimesSlot, setSelectedTimesSlot] = useState([]);
  const [showTimeSlotError, setShowTimeSlotError] = useState(false); // State to manage time slot error
  const [slotIdMap, setSlotIdMap] = useState({});
  const [subtotal, setSubtotal] = useState(0);
  const [selectedDates, setSelectedDates] = useState([]);
  const [weekSelectionError, setWeekSelectionError] = useState(false); // Track week selection errors
  const [perWeekSlotError, setperWeekSlotError] = useState(false); // Track week selection errors
  const [remainingSessions, setRemainingSessions] = useState(0);
  const [selectedSlotIds, setSelectedSlotIds] = useState([]);
  const [slotIdDateMap, setSlotIdDateMap] = useState({});
  const [individualOneSlotVal, setIndividualOneSlotVal] = useState(false);
  const [chooseDate,setChooseDate] = useState('')
  const [inputVariablePrice,setInputVariablePrice] = useState(0)
  const [timeSlotError,setTimeSlotError] = useState('')

  const calculateRemainingSessions = () => {
    const totalSessionsRequired = (data?.no_of_sessions || 2) * (data?.package_timeframe || 3);
    const totalSelected = selectedTimes.length;

    // If no time slots are selected, do not reduce remaining sessions
    if (totalSelected === 0) {
      return totalSessionsRequired;
    }

    const remaining = totalSessionsRequired - totalSelected;
    console.log('Remaining sessions calculated:', remaining); // Debugging line
    return remaining > 0 ? remaining : 0;
  };

  useEffect(() => {
    setRemainingSessions(calculateRemainingSessions());
    console.log('this is remaining session', remainingSessions);
  }, [slotDisplay]); // Update remaining sessions whenever selectedTimes change


  const convertTo24HourFormat = useCallback((time) => {
    const [hours, minutesPart] = time.split(':');
    const [minutes, period] = minutesPart.match(/[a-zA-Z]+|[0-9]+/g);
    let hours24 = parseInt(hours, 10);

    if (period.toLowerCase() === 'pm' && hours24 !== 12) hours24 += 12;
    if (period.toLowerCase() === 'am' && hours24 === 12) hours24 = 0;

    return `${hours24.toString().padStart(2, '0')}:${minutes}`;
  }, []);
  const fetchData = useCallback(async (formattedDate) => {
    try {
      const response = await getApi(`/user/service/date?offerId=${data?.id}&monthYear=${formattedDate}`);
      const dates = response.data;
      // Get the current date and time
      const currentDate = new Date();
      // Filter the dates to include only those greater than the current date
      const filteredDates = dates.filter(dateStr => {
        const date = new Date(dateStr);
        return date > currentDate;
      });
      // Convert dates from the API to the desired format
      const formattedDates = filteredDates.map(dateStr => {
        const date = new Date(dateStr);
        // Set time to midnight
        date.setUTCHours(0, 0, 0, 0);
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
      });

      setMarkedDates(formattedDates);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [data?.id]);
  useEffect(() => {
    const basePrice = inputVariablePrice || (data?.price || data?.minimum_price) || 0;
    setSubtotal(basePrice);
    // setSubtotal(basePrice * slotDisplay.length);
  }, [slotDisplay, (data?.price || data?.minimum_price),inputVariablePrice]);

  const fetchSlotsData = useCallback(async (formattedDate) => {
    try {
      const response = await getApi(`/user/service-slots?offerId=${data?.id}&date=${formattedDate}`);
      console.log('Service slots:', response.data);
      const slots = response.data;
      const newIdMap = slots.reduce((acc, slot) => {
        const formattedTime = slot.start_time.trim(); // Trim any extra spaces
        acc[`${formattedDate}_${formattedTime}`] = slot.id;
        return acc;
      }, {});

      // Merge newIdMap with existing slotIdMap
      setSlotIdMap(prevMap => ({
        ...prevMap,    // Keep previous entries
        ...newIdMap    // Add/overwrite with new slots
      }));

      setAvailableTimes(slots.map(slot => slot.start_time.trim())); // Set available times

      console.log('Merged slotIdMap:', newIdMap); // Log the updated map

    } catch (error) {
      console.error('Error fetching slots data:', error);
    }
  }, [data?.id]);

  const formatDateForAPI = (date) => {
    const month = date.getMonth() + 1; // Months are zero-indexed
    const year = date.getFullYear();
    return `${month}-${year}`;
  };

  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'long',      // "April"
      day: 'numeric',    // "7"
      year: 'numeric'
      // weekday: 'short',  // "Mon"
      // day: 'numeric',    // "7"
      // month: 'long'      // "April"
    });
  };
  const formatDateDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'long',      // "April"
      day: 'numeric', 
      // weekday: 'short',  // "Mon"
      // day: 'numeric',    // "7"
      // month: 'long'      // "April"
    });
  };
  const formatDateWeek = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'short', day: 'numeric', month: 'long' };
    
    const parts = date.toLocaleDateString('en-US', options).split(' ');
    
    return `${parts[0]} ${parts[2]} ${parts[1]}`;
  };
  // const formatDateWeekSlot = (dateString) => {
  //   // Convert DD/MM/YYYY to MM/DD/YYYY
  //   const [day, month, year] = dateString.split('/');
  //   const validDateString = `${month}/${day}/${year}`;
  
  //   const date = new Date(validDateString); // Now it's valid
  //   const options = { weekday: 'short', day: 'numeric', month: 'short' };
  
  //   const parts = date.toLocaleDateString('en-US', options).split(' ');
  
  //   return `${parts[0]} ${parts[2]} ${parts[1]}`;
  // };


  const formatDateForSlotsAPI = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  };

  //this is only select functionality
  const handleTimeSelect = useCallback((time, date) => {
    setSelectedTimesSlot(prevTimes => {
      const timeSlot = `${date}, ${time}`;
      return [timeSlot];
    });
    // setSelectedTimes(prevTimes => {
    //   // Remove any previous time slot for this date
    //   const newTimes = prevTimes.filter(slot => !slot.startsWith(`${date}, `));
    //   // Add the new selected time slot
    //   const timeSlot = `${date}, ${time}`;
    //   return [...newTimes, timeSlot];
    // });

    // Remove the error message when at least one time slot is selected
    if (selectedTimes.length > 0) {
      setShowTimeSlotError(false);
    }
  }, [selectedTimes]);

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(':');
    let hours12 = parseInt(hours, 10);
    const ampm = hours12 >= 12 ? 'pm' : 'am';
    hours12 = hours12 % 12 || 12; // Convert 0 to 12 for midnight
    return `${hours12}:${minutes} ${ampm}`;
  };


  // Function to convert 24-hour time to 12-hour format
  const convertTo12HourFormatSlot = (timeString) => {
    let [hours, minutes] = timeString.split(':');
    let period = 'am';

    hours = parseInt(hours, 10);

    if (hours >= 12) {
      period = 'pm';
      if (hours > 12) hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${period}`;
  };



  const convertSlotTo12HourFormat = (slot) => {
    const [date, time] = slot.split(', '); // Split the date and time
    const [hours, minutes] = time.split(':');
    let hours12 = parseInt(hours, 10);
    const ampm = hours12 >= 12 ? 'pm' : 'am';
    hours12 = hours12 % 12 || 12; 
    return `${hours12}:${minutes} ${ampm}`;
    // return `${date}, ${hours12}:${minutes} ${ampm}`;
  };

  const handleButtonClick = () => {
    if (selectedTimes.length === 0) {
      setShowTimeSlotError(true); // Show error if no time slots are selected
    } else {
      setShowTimeSlotError(false); // Hide error if time slots are selected
      setFiltersModal(true); // Open the modal if time slots are selected
    }
  };
  const removeTimeSlot = (slot) => {
    const [day, month, year] = slot.split(",")[0].split("/");
        const removedate = `${year}-${month}-${day}`;
    setChooseDate((prevDates) => prevDates.filter((date) => date !== removedate));
    setSelectedTimes(prevTimes => {
      const newTimes = prevTimes.filter(time => time !== slot);
      setSlotDisplay(newTimes);
      return newTimes;
    });
    setIndividualOneSlotVal(false)
  };

  console.log('thisi is selectedSlotIdsselectedSlotIdsselectedSlotIds', selectedSlotIds);
  console.log('thisi is selectedSlotIdsselectedSlotIdsselectedSlotIds', selectedSlotIds.length);
  const handleChooseButtonClick = () => {
    console.log('thisi is availabela', selectedTimes);
// // if(data?.session_type==='1' && data?.session_offer_type === '1' && selectedSlotIds?.length === 0){
//   setIndividualOneSlotVal(true)
// }
// else {
//   setIndividualOneSlotVal(false)
// }
    if (selectedTimes.length >= 1) {
      setShowTimeSlotError(false); // Remove error when a time slot is selected
    }
   
 
  // Function to convert date from "dd/MM/yyyy" to "yyyy-MM-dd"
  const formatDateToSlotMapFormat = (dateString) => {
    const [day, month, year] = dateString.split('/'); // Split the date string
    return `${year}-${month}-${day}`; // Return the date in "yyyy-MM-dd" format
  };

  // Map selected times to their corresponding slot IDs from slotIdMap based on both date and time
  const newSlotIds = selectedTimesSlot.map((entry) => {
  // const newSlotIds = selectedTimes.map((entry) => {
    const time = entry.split(', ')[1].trim(); // Extract time from entry
    const date = entry.split(', ')[0].trim(); // Extract date from entry

    // Convert the date to match the format in slotIdMap
    const formattedDate = formatDateToSlotMapFormat(date);
    const parts = formattedDate.split("-"); 
    const reformattedDate = `${parts[0]}-${parts[1]}-${parts[2]}`;
    setChooseDate((prevDates) => {
      if (prevDates.includes(reformattedDate)) {
          return prevDates; 
      }
      return [...prevDates, reformattedDate];
  });

    // Create a combined key of date and time
    const slotIdKey = `${formattedDate}_${time}`;
    const slotId = slotIdMap[slotIdKey]; // Look up slot ID by formatted date and time

    if (!slotId) {
      console.warn(`No slot ID found for ${formattedDate} at ${time}`); // Warn if no ID is found
    }

    return slotId || 'ID not found'; // Return slot ID or log a warning if it's missing
  });

  // Merge newSlotIds with existing slot IDs without replacing previous ones
  // setSelectedSlotIds((prevSlotIds) => {
  //   const mergedSlotIds = [...prevSlotIds, ...newSlotIds];
  //   const uniqueSlotIds = Array.from(new Set(mergedSlotIds));
  //   return uniqueSlotIds;
  // });
  const updatedSlotIds = Array.from(new Set([...selectedSlotIds, ...newSlotIds]));
  setSelectedSlotIds(updatedSlotIds);
  setSelectedTimesSlot([])
  console.log('New selected slot IDs:', newSlotIds);
  

  // setSelectedTimes((prevTimes) => {
  //   const updatedTimes = [...prevTimes, ...selectedTimesSlot];
  //   setSlotDisplay(updatedTimes);
  //   return updatedTimes;
  // });
  setSelectedTimes((prevTimes) => {
    const updatedTimesMap = new Map();
    const allTimes = [...prevTimes, ...selectedTimesSlot];
    allTimes.forEach((time) => {
      const [date, timeString] = time.split(", ");
      if (!updatedTimesMap.has(date)) {
        updatedTimesMap.set(date, time);
      } else {
        const existingTime = updatedTimesMap.get(date);
        const latestTime = new Date(`1970-01-01T${existingTime.split(", ")[1]}Z`);
        const newTime = new Date(`1970-01-01T${timeString}Z`);
        
        // if (newTime > latestTime) {
          updatedTimesMap.set(date, time);
        // }
      }
    });
    const updatedTimes = Array.from(updatedTimesMap.values());
    setSlotDisplay(updatedTimes);
    return updatedTimes;
  });
  
    // setSlotDisplay(selectedTimes);
    setRemainingSessions(() => calculateRemainingSessions());
    // if(data?.session_type==='1' && data?.session_offer_type === '1' && slotDisplay?.length > 0){
    if(data?.session_type==='1' && data?.session_offer_type === '1' && selectedTimes?.length > 0){
    // if(data?.session_type==='1' && data?.session_offer_type === '1' && selectedTimes?.length > 0 && data?.session_offer_type!=='2'){
    // if(data?.session_type==='1' && data?.session_offer_type === '1' && updatedSlotIds?.length > 0){
        setIndividualOneSlotVal(true)
      }
      else {
        setIndividualOneSlotVal(false)
      }
      ///////
      if(data?.session_type==='1' && data?.session_offer_type==='1' && selectedTimesSlot?.length===1){
        setIndividualOneSlotVal(true)
      }


  };



  const fetchInitialData = useCallback(async () => {
    const formattedDate = formatDateForAPI(activeStartDate);
    await fetchData(formattedDate);
  }, [activeStartDate, fetchData]);

  // Function to calculate the week number for a given date
  // const getWeekNumber = (date) => {
  //   const startOfYear = new Date(date.getFullYear(), 0, 1);
  //   const pastDaysOfYear = (date - startOfYear) / 86400000;
  //   return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
  // };

  const getWeekNumber= (date) =>{
    // Adjust the date to make Monday the start of the week
    const adjustedDate = new Date(date);
    const day = adjustedDate.getDay();
    const diff = (day === 0 ? -6 : 1) - day; // Adjust so Monday = 0, Sunday = 6
    adjustedDate.setDate(adjustedDate.getDate() + diff);
  
    // Get the first day of the year
    const startOfYear = new Date(adjustedDate.getFullYear(), 0, 1);
    const startDay = startOfYear.getDay();
    const offset = (startDay === 0 ? -6 : 1) - startDay; // Adjust so Monday = 0
    startOfYear.setDate(startOfYear.getDate() + offset);
  
    // Calculate week number
    const diffInMs = adjustedDate - startOfYear;
    const diffInDays = diffInMs / (24 * 60 * 60 * 1000);
    return Math.ceil((diffInDays + 1) / 7); // +1 to ensure proper week rounding
  }

console.log('mamamammamamamammamamamamama',markedDates);

  const handleDateClick = useCallback(async (date) => {
    const formattedDate = formatDateForSlotsAPI(date);
    const weekNumber = getWeekNumber(date); // Get the week number of the selected date


    setSelectedDates((prevDates) => {
      const updatedDates = [...prevDates, { date: formattedDate, week: weekNumber }];
      const weekDates = updatedDates.filter(d => d.week === weekNumber); // Get all selected dates for the current week

      // Convert markedDates to Date objects for accurate comparison
      const availableWeekDates = markedDates.filter((markedDate) => {
        const markedDateObj = new Date(markedDate); // Convert string to Date
        return getWeekNumber(markedDateObj) === weekNumber; // Check if it belongs to the same week
      });

      // Log to help debugging
      console.log('Selected dates in week:', weekDates);
      console.log('Available dates in week:', availableWeekDates);
      console.log('No of sessions required:', data?.no_of_sessions);

      // Show error if selected dates in the week are less than required and no more dates are available
      if (weekDates.length < data?.no_of_sessions && availableWeekDates.length <= weekDates.length && data?.session_offer_type !=='2') {
        setWeekSelectionError(true); // Show error
      } else {
        setWeekSelectionError(false); // Hide error
      }


      return updatedDates;
    });

    // Fetch available time slots for the selected date
    await fetchSlotsData(formattedDate);
  }, [data?.no_of_sessions, markedDates, fetchSlotsData]);

  const validateSelectedDates = () => {
    // Get all weeks and their date counts
    const validateDates =[]
    const validateMonths =[]
    slotDisplay.forEach((entry) => {
      const [datePart] = entry.split(",");
      const [day, month, year] = datePart.trim().split("/");
    const combineDate = `${year}-${month}-${day}`;
    const date = new Date(combineDate);
      const formattedDate = formatDateForSlotsAPI(date);
      const weekNumber = getWeekNumber(date);
      const monthKey = `${year}-${month}`;
      validateDates.push({ date:formattedDate, week: weekNumber });
      validateMonths.push({ date: formattedDate, month: monthKey });
    });
    const weeks = validateDates.reduce((acc, { week }) => {
    // const weeks = selectedDates.reduce((acc, { week }) => {
      // Ensure that no week accumulates more than the allowed number of sessions
      if (acc[week] === undefined) {
        acc[week] = 1;
      } else if (acc[week] < data?.no_of_sessions) {
        acc[week] += 1;
      }
      return acc;
    }, {});
    const months = validateMonths.reduce((acc, { month }) => {
      if (!acc[month]) {
        acc[month] = 1;
      } else if (acc[month] < data?.no_of_sessions) {
        acc[month] += 1;
      }
      return acc;
    }, {});
    // Debugging logs
    console.log('Weeks object:', weeks); // Logs weeks and their date counts
    console.log('Number of sessions required per week:', data?.no_of_sessions); // Logs the number of required sessions
    // Check how many weeks have at least the required number of dates
    const validWeeksCount = Object.values(weeks).filter(count => count >= data?.no_of_sessions).length;
    const validMonthsCount = Object.values(months).filter(
      (count) => count >= data?.no_of_sessions
    ).length;
    // Ensure the number of such valid weeks meets the requirement
    // let result;
    // if(data?.package_period !=='months'){
    const result = (data?.package_period !=="day") ? validWeeksCount >= data?.package_timeframe : slotDisplay?.length >= data?.package_timeframe; // Replace 2 with your required number of weeks
    // }else{
    // result = data?.package_period !=="day" ? validMonthsCount >= data?.package_timeframe : slotDisplay?.length >= data?.package_timeframe; // Replace 2 with your required number of weeks
    // }
    // const result = validWeeksCount >= data?.package_timeframe; // Replace 2 with your required number of weeks
    
    console.log('Validation result:', result); // Logs the validation result (true/false)
  
    return result;
  };
  
  
  


  const handleActiveStartDateChange = useCallback(({ activeStartDate }) => {
    setActiveStartDate(activeStartDate);
  }, []);

  useEffect(() => {
    fetchInitialData();
  }, [activeStartDate, fetchInitialData]);


  const onSubmit = async (values) => {
    // {(data?.session_type === '1' && slotDisplay.length === 0) && setTimeSlotError('Please select the time slot')}
    if(data?.session_type === '1' && slotDisplay.length === 0){
      setTimeSlotError('Please select the time slot')
    }
    if (data?.session_type === '1' && data?.session_offer_type == 2) {
      // Validate selected dates
      if(slotDisplay?.length < (data?.no_of_sessions * data?.package_timeframe)){
        // setTimeSlotError('Please select the time slot')
        return; 
      }
      if (!validateSelectedDates()) {
        // Set an error message if the validation fails
        setperWeekSlotError(true)
        // alert('Number of timeslots per week is not valid');
        return;
      }
      else if (selectedDates.length === 0){
        setperWeekSlotError(true)
      }
      else {
        setperWeekSlotError(false)

      }
    }

    // this is commented just for testing
    // if (remainingSessions === data?.no_of_sessions) {
    //   setperWeekSlotError(false)
    // }

    console.log('this is before submit', remainingSessions);
     if (remainingSessions === 0) {
        setperWeekSlotError(false)
      }

    console.log('**********values******', values);
    console.log('**********selectedTimesselectedTimesselectedTimesselectedTimes******', selectedTimes);

    const price = subtotal || data?.minimum_price || values?.price;
    // Extract the IDs of selected time slots
    // const timeSlotIds = selectedTimes.map(slot => {
    //   const time = slot.split(', ')[1].trim(); // Get the time part and trim any spaces
    //   console.log('Checking time:', time); // Log the time being checked
    //   const slotId = slotIdMap[time];
      
    //   if (!slotId) {
    //     console.warn(`No slot ID found for time: ${time}`); // Warn if no ID is found
    //   }
    
    //   return slotId || 'ID not found'; // Log when an ID is not found
    // });
    
    const timeSlotIds = selectedSlotIds
    // Check the extracted slot IDs
    console.log('Selected Slot IDs:', timeSlotIds);
    
    // Final submit log to check before submission
    console.log('submitBooking called', { timeSlotIds, price });
    try {
      const dataToSend = {
        slotIds: timeSlotIds,
        price,
        offerServiceId: data?.id,
        slotDisplay:data?.session_type==='1' ? slotDisplay :data?.slots
      };
      setDataToPaymentModal(dataToSend)
      console.log('Data to send:', dataToSend);
      // const response = await postApi('/user/book-slot', dataToSend);
      // if (response.statusCode === 200) {
      if(data?.travel_area !== '' && data?.location_type === '3'){
        setConfirmLocation(true)
        // setFiltersModal(true); // Show error if no time slots are selected
      } else{
        setFiltersModal(true);
      }

        closeModal()
      // }
      // else {
      //   setFiltersModal(true);
      //   closeModal()
      // }
      setTimeSlotError('')
    } catch (error) {
      console.error('Error submitting booking:', error);
    }
  };

  const validatePrice = (value) => {
    let error;
    if (!value) {
      setInputVariablePrice(0)
      error = 'Price is required.';
    } else if (value < data?.minimum_price || value > data?.maximum_price) {
      setInputVariablePrice(0)
      error = `Enter a price between $${data?.minimum_price} and $${data?.maximum_price}.`;
    } else{
      setInputVariablePrice(value)
      setSubtotal(value)
      // setSubtotal(value*slotDisplay?.length)
    }
    return error;
  };

  return (
    <div className="detail-page booking">
      <div className="container">
        <h2>{data?.title || 'Yoga'} </h2>
        {(data?.session_type === '1' && data?.session_offer_type == 2 && (data?.package_type === '1' || data?.package_type === '2' ||data?.package_type === '4')) && (
          <h3>
            Select {data?.no_of_sessions || 2} dates per {data?.package_period || 'month'} for {data?.package_timeframe || 3} {data?.package_period || 'months'} ({((data?.no_of_sessions || 1) * data?.package_timeframe) || 6} sessions)
          </h3>
        )}
        {(data?.session_type === '2' && data?.session_offer_type == 2 && (data?.package_type === '1' || data?.package_type === '2' ||data?.package_type === '4')) && (
          <h3>
            {data?.no_of_sessions || 2}x per {data?.package_period || 'month'} session for {data?.package_timeframe || 3} {data?.package_period || 'months'} ({((data?.no_of_sessions || 1) * data?.package_timeframe) || 6} sessions)
          </h3>
        )}  
        {(data?.session_type === '1' && data?.session_offer_type == 1) && (
          <h3>
            One time Consultation
          </h3>
        )}
        {(data?.session_type === '2' && data?.session_offer_type == 1) && (
          <h3>
            One time Workshop for {data?.slots?.length} days
          </h3>
        )}
        <div className="detail-main">
        {data?.session_type==='2' && data?.session_offer_type === '1' && <p class="one-time-selects w-100 text-start mt-2"> One time Workshopfor {data?.totaldates} days</p>}
          <Formik
            initialValues={initialValues}
            // validationSchema={bookingInterviews}
            onSubmit={(values) => onSubmit(values)} >
            {({ setFieldValue }) => (
              <Form>
                <div className="row start-row-selects">
                  <div className="col-sm-8 col-lg-6 col-xl-6 select-date-data">
                    <div className="select-date">
                      <h4>Select date </h4>
                      <div className="calender">
                        <Calendar
                          onChange={onChange}
                          value={value}
                          onActiveStartDateChange={handleActiveStartDateChange}
                          tileClassName={({ date, view, activeStartDate }) => {
                            let className = '';
                          
                            // Format the date
                            const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                            // Check if the date is from the previous month in month view
                            if (view === 'month' && date.getMonth() < activeStartDate.getMonth()) {
                              className += 'previous-date '; // Add class for previous month dates
                            }
                          
                            // Check if the date is from the next month in month view (we have added class previous month because it has css class)
                            if (view === 'month' && date.getMonth() > activeStartDate.getMonth()) {
                              className += 'previous-date '; // Add class for next month dates
                            }
                          
                            // Check if the date is in markedDates
                            // if (markedDates.includes(formattedDate)) {
                            //   className += 'highlight '; // Add class for marked dates
                            // } else {
                            //   className += 'sanket '; // Add class for non-marked dates
                            // }
                            if (value && formattedDate === new Date(value).toLocaleDateString('en-CA')) {
                              className += ' highlight-black ';
                            }
                            if(markedDates.includes(formattedDate) && data?.session_type=='2'){
                              className += 'selected-date choose-date ';
                            }
                            if (markedDates.includes(formattedDate) && data?.session_type!='2') {
                              className += 'selected-date ';
                            }
                            if(chooseDate.includes(formattedDate)){
                            // if(chooseDate == formattedDate){
                              className += 'choose-date '
                            }
                            return className.trim(); // Return trimmed className to avoid trailing spaces
                          }}
                          
                          
                          tileDisabled={({ date }) => {
                            const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                            return !markedDates.includes(formattedDate); // Disable dates not in markedDates
                          }}
                          onClickDay={handleDateClick}
                        />
                        {/* Display the error only when the selected dates for a week are less than required */}
                        {data?.session_type === '1' && data?.session_offer_type == 2 && (
                          <>
                            {weekSelectionError && (
                              <div className="error-message">
                                <p className='error-text'>Please select at least {data?.no_of_sessions} dates per week.</p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={`col-sm-12 col-lg-6 col-xl-6 position-relative select-date-&-time ${availableTimes.length === 0?' ':'select-timepayment-set'}`}>
                    <div class="timepayment-set-ui">
                    <div className="select-date">

                      {data?.session_type === '1' && data?.session_offer_type === '1' && (
                        <>
                          <div className=''>
                            <h4>Select time for {formatDateDate(value)}</h4>
                            <div className="row scroll-time-set" >
                              {availableTimes.length === 0 ? (
                                <p>No time slots available</p>
                              ) : (
                                availableTimes.map((time) => (
                                  <div className="col-sm-3" key={time}>
                                    <label className="time">
                                      <input
                                        type="radio"
                                        name={`timeSlot-${value.toLocaleDateString()}`} // Use the date to group time slots
                                        value={time}
                                        checked={selectedTimesSlot.includes(`${value.toLocaleDateString()}, ${time}`)}
                                        onChange={() => handleTimeSelect(time, value.toLocaleDateString())}
                                      />
                                      <span className="checkmark">
                                        <h5>{convertTo12HourFormat(time)}</h5>
                                      </span>
                                    </label>
                                  </div>
                                ))
                              )}
                            </div>
                            {availableTimes.length > 0 && ( // Conditionally render button
                              <button type="button" className={`btn btn-primary ${((weekSelectionError || individualOneSlotVal) || selectedTimesSlot.length === 0) ? 'disabled' : ''}`} onClick={handleChooseButtonClick} disabled={(weekSelectionError || individualOneSlotVal)}>
                                Choose time
                              </button>
                            )}
                            <div className='select-date'>
                              {availableTimes.length > 0 && (
                                <h4>{data?.session_type ==='1'? 'Selected session' :'Sessions date and time:'} </h4>
                              )}
                              <div className='select-date-scroll'> 
                              {slotDisplay.length > 0 && slotDisplay.map((slot, index) => (
                                <div key={index} className="selected-slot">
                                     <div className='first-slot'> <span>{data?.session_type === '1' && <img src={check} alt="close" />}{formatDateWeekSlot(slot.split(',')[0])}
                                     
                                     </span>
                                     </div>
                                     <div className='second-slot'> {convertSlotTo12HourFormatAmPm(slot,data?.sessionDuration)}</div>
                                  {/* <span>{data?.session_type === '1' && <img src={check} alt="close" />}{convertSlotTo12HourFormat(slot)}</span> */}
                                  <div className='third-slot'><span className='cross' onClick={() => removeTimeSlot(slot)}><img src={remove} alt="close" /></span></div>
                                </div>
                              ))}
                               {slotDisplay.length === 0 &&
                                <span>{timeSlotError !== '' && timeSlotError}</span>
                               }
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {(data?.session_type === '1' && data?.session_offer_type === '2' && data?.package_type === '4') && (
                        <>
                          <div className='data-scroll'>
                            <h4>Select time for {formatDateDate(value)}</h4>
                            <div className="row" >
                              {availableTimes.length === 0 ? (
                                <p>No time slots available</p>
                              ) : (
                                availableTimes.map((time) => (
                                  <div className="col-sm-3" key={time}>
                                    <label className="time">
                                      <input
                                        type="radio"
                                        name={`timeSlot-${value.toLocaleDateString()}`} // Use the date to group time slots
                                        value={time}
                                        checked={selectedTimesSlot.includes(`${value.toLocaleDateString()}, ${time}`)}
                                        onChange={() => handleTimeSelect(time, value.toLocaleDateString())}
                                      />
                                      <span className="checkmark">
                                        <h5>{convertTo12HourFormat(time)}</h5>
                                      </span>
                                    </label>
                                  </div>
                                ))
                              )}
                            </div>
                            {availableTimes.length > 0 && ( // Conditionally render button
                              <button type="button" className={`btn btn-primary ${(weekSelectionError || selectedTimesSlot.length === 0) ? 'disabled' : ''}`} onClick={handleChooseButtonClick} disabled={weekSelectionError}>
                                Choose time
                              </button>
                            )}
                            <div className='select-date'>
                              {availableTimes.length > 0 && (
                                <h4>{data?.session_type ==='1'? `Selected sessions ${slotDisplay?.length>0 && ': '+slotDisplay.length}` :'Sessions date and time:'} </h4>
                              )}
                              {slotDisplay.length > 0 && slotDisplay.map((slot, index) => (
                                <div key={index} className="selected-slot">
                                  {/* <span>{data?.session_type === '1' && <img src={check} alt="close" />}{formatDateWeekSlot(slot.split(',')[0])} {convertSlotTo12HourFormat(slot)}</span> */}
                                <div className='first-slot'>  <span>{data?.session_type === '1' && <img src={check} alt="close" />}
                                      {formatDateWeekSlot(slot.split(',')[0])}</span></div>
                                    <div className='second-slot'> {convertSlotTo12HourFormatAmPm(slot,data?.sessionDuration)}</div>
                                  {/* <span>{data?.session_type === '1' && <img src={check} alt="close" />}{formatDateWeekSlot(slot.split(',')[0])} {convertSlotTo12HourFormatAmPm(slot,data?.sessionDuration)}</span> */}
                                  {/* <span>{data?.session_type === '1' &&<img src={check} alt="close" />}{convertSlotTo12HourFormat(slot)}</span> */}
                                  <div className='third-slot'>  <span className='cross' onClick={() => removeTimeSlot(slot)}><img src={remove} alt="close" /></span></div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}

                      {(data?.session_type === '1' && data?.session_offer_type === '2' && (data?.package_type === '1' || data?.package_type === '2')) && (
                        <>
                          <div className='data-scroll'>
                            <h4>Select time for {formatDateDate(value)}</h4>
                            <div className="row" >
                              {availableTimes.length === 0 ? (
                                <p>No time slots available</p>
                              ) : (
                                availableTimes.map((time) => (
                                  <div className="col-sm-3" key={time}>
                                    <label className="time">
                                      <input
                                        type="radio"
                                        name={`timeSlot-${value.toLocaleDateString()}`} // Use the date to group time slots
                                        value={time}
                                        checked={selectedTimesSlot.includes(`${value.toLocaleDateString()}, ${time}`)}
                                        onChange={() => handleTimeSelect(time, value.toLocaleDateString())}
                                        disabled={weekSelectionError}
                                        // disabled={weekSelectionError || (slotDisplay.length === (data?.no_of_sessions * data?.package_timeframe))}
                                      />
                                      <span className="checkmark">
                                        <h5>{convertTo12HourFormat(time)}</h5>
                                      </span>
                                    </label>
                                  </div>
                                ))
                              )}
                              {showTimeSlotError && (
                                <ErrorMessage name="selectedTimes" component="div" className="text-danger" />
                              )}
                            </div>

                            {availableTimes.length > 0 && ( // Conditionally render button
                              <button type="button" className={`btn btn-primary ${(weekSelectionError || selectedTimesSlot.length === 0) ? 'disabled' : ''}`} onClick={handleChooseButtonClick} disabled={weekSelectionError}>
                                Choose time
                              </button>
                            )}
                            <div className='select-date'>
                              {availableTimes.length > 0 && (
                                <h4>{data?.session_type ==='1'? `Selected sessions ${slotDisplay?.length>0 && ': '+slotDisplay.length}` :'Sessions date and time:'} </h4>
                              )}
                              {slotDisplay.length > 0 && slotDisplay.map((slot, index) => (
                                <div key={index} className="selected-slot selectedtime-div">
                                  {/* <span>{data?.session_type === '1' &&<img src={check} alt="close" />}{convertSlotTo12HourFormat(slot)}</span>
                                  <span className='cross' onClick={() => removeTimeSlot(slot)}><img src={remove} alt="close" /></span> */}
                                  <span>
                                  <div className='d-flex w-100'>
                                  <span>{data?.session_type === '1' && <img src={check} alt="close" />}{formatDateWeekSlot(slot.split(',')[0])} {convertSlotTo12HourFormatAmPm(slot,data?.sessionDuration)}</span>
                                  {/* <div className='first-time'>{data?.session_type === '1' && <img src={check} alt="close" />}{convertSlotTo12HourFormat(slot)} </div> */}
                                  <div className='second-time'>  <span className='cross' onClick={() => removeTimeSlot(slot)}><img src={remove} alt="close" /></span></div>
                               </div>
                                  </span>
                                </div>
                              ))}
                              {slotDisplay.length > 0 && (
                                <>
                                  {[...Array(remainingSessions)].map((_, index) => (
                                    <div key={index} className="selected-slot">
                                      <span><img src={uncheckCircle} alt="close" /></span>
                                    </div>
                                  ))}
                                </>
                              )}


                            </div>
                          </div>

                          {data?.session_type === '1' && data?.session_offer_type == 2 && (
                            <>
                              <div className="remaining-sessions">
                                {slotDisplay.length > 0 && remainingSessions > 0 ? (
                                  <p className='error-text'>Please select {remainingSessions} additional {remainingSessions === 1 ? 'session' : 'sessions'} to complete the package.</p>
                                ) : slotDisplay.length > 0 && remainingSessions === 0 ? (
                                  <p>All required sessions are selected.</p>
                                ) : null}
                              </div>


                            </>
                          )}
                          {data?.session_type === '1' && data?.session_offer_type == 2 && (
                            <>
                              {perWeekSlotError && (
                                <div className="error-message">
                                  <p className='error-text'>You haven't selected  {data?.no_of_sessions} dates per week.</p>
                                </div>

                              )}
                            </>
                          )}

                        </>
                      )}
                      {data?.session_type === '2' && data?.session_offer_type === '2' && (
                        <>

                          <div className='data-scroll'>
                            <h4>
                              Starts: {formatDate(data?.slots?.length > 0 ? data.slots[0]?.date : '-')+' '} 
                              Ends: {formatDate(data?.slots?.length > 0 ? data.slots[data.slots.length - 1]?.date : '-')}
                            </h4>
                            <div className='select-date'>
                              <h4>{data?.session_type ==='1'? 'Selected session' :'Sessions date and time:'} </h4>
                              {data?.slots?.length > 0 && data?.slots?.map((slot, index) => (
                                // <div key={index} className="selected-slot">
                                //   <span>{data?.session_type === '1' &&<img src={check} alt="close" />}{formatDate(slot?.date)}    {convertTo12HourFormatSlot(slot?.start_time)} - {convertTo12HourFormatSlot(slot?.end_time)}</span>
                                // </div>
                                <div key={index} className="selected-slot selectedtime-div">
                                  
                                  {data?.session_type === '1' &&<img src={check} alt="close" />}
                                 
                                  <div className='first-slot'> {formatDateWeek(slot?.date)} </div>
                                  <div className='second-slot'> {convertTo12HourFormatSlot(slot?.start_time)} - {convertTo12HourFormatSlot(slot?.end_time)}</div>
                              
                                </div>
                              ))}
                            </div>
                          </div>

                        </>
                      )}
                      {data?.session_type === '2' && data?.session_offer_type === '1' && (
                        <>

                          <div className='data-scroll'>
                            <h4>
                              Starts: {formatDate(data?.slots?.length > 0 ? data.slots[0]?.date : '-')+' '}{'\u00A0'} 
                              Ends: {formatDate(data?.slots?.length > 0 ? data.slots[data.slots.length - 1]?.date : '-')}
                            </h4>
                            <div className='select-date'>
                              <h4>{data?.session_type ==='1'? 'Selected session' :'Sessions date and time:'} </h4>
                              {data?.slots?.length > 0 && data?.slots?.map((slot, index) => (
                                <div key={index} className="selected-slot">
                                  {/* <span>
                                  {data?.session_type === '1' && <img src={check} alt="close" /> }{formatDate(slot?.date)}    {convertTo12HourFormatSlot(slot?.start_time)} - {convertTo12HourFormatSlot(slot?.end_time)}</span> */}
                              
                                  {data?.session_type === '1' &&<img src={check} alt="close" />}
                                  <div className='first-slot'>{formatDateWeek(slot?.date)}  </div>
                                  <div className='second-slot'>{convertTo12HourFormatSlot(slot?.start_time)} - {convertTo12HourFormatSlot(slot?.end_time)}</div>
                                 
                                </div>
                              ))}
                            </div>
                          </div>

                        </>
                      )}
                      </div>
                      </div>

                      {/* Conditional Rendering Based on session_type and session_offer_type */}
                      {(data?.session_offer_type === "2" || data?.session_offer_type === "1") && (
                      // {data?.session_type === "1" && (data?.session_offer_type === "2" || data?.session_offer_type === "1") ? (
                        <>
                          {data?.pricing_type === '2' && (
                          // {data?.pricing_type === '2' && data?.session_type !== '2' && (
                            <div className='enter-price sds'>
                              <p className='enter-label'>Enter a price between ${data?.minimum_price} and ${data?.maximum_price}</p>
                              <label htmlFor="price">Your price</label>
                              <Field
                                type="number"
                                id="price"
                                name="price"
                                min={data?.minimum_price}
                                max={data?.maximum_price}
                                step="0.01"
                                placeholder="Enter your price"
                                validate={validatePrice}
                              />
                              <div className='message-anna'>
                                {data?.message_to_user !== null && (
                                  <>
                                    <h4>Message from {data?.userData?.name}:</h4>
                                    <p> {data?.message_to_user} 
                                      {/* <a>Yoga for All Foundation</a> */}
                                      </p>
                                  </>
                                )}
                              </div>
                              <ErrorMessage name="price" component="div" className="text-danger" />
                            </div>
                           )} 
                        </>
                      ) }
                      
                   
                    </div>
                 
                </div>
                <div class="row">
                <div class="col-lg-6 col-md-12">
                  </div>
                  <div class="col-lg-6 col-md-12">

                    {(data?.session_offer_type === "2" || data?.session_offer_type === "1") ? (
                      <>
                        {data?.package_type !== "4" && (
                          <div className='subtotal'>
                            <p><strong>Subtotal Price:</strong> <b>{data?.session_type==='1' ? (slotDisplay?.length>0 ? '$'+(subtotal || data?.price || data?.minimum_price || '-'):'$ 0.00') :('$'+(subtotal || data?.price || data?.minimum_price || '-'))}</b> <br></br>
                              {/* <p><strong>Subtotal Price</strong> <b>${subtotal || (data?.price || data?.minimum_price)*slotDisplay?.length || '-'}</b> <br></br> */}
                              {/* TAX of 13%
                            Total Price ${(data?.price || data?.minimum_price)*slotDisplay?.length || '-'} */}
                            </p>
                            <button type="submit" disabled={(data?.session_type === '1' && data?.session_offer_type === '1' && slotDisplay?.length === 0) || (data?.pricing_type === '2' && inputVariablePrice === 0)} className={`choose ${(data?.session_type === '1' && data?.session_offer_type === '1' && slotDisplay?.length === 0) ? 'disabled' : ''}`}>Complete Booking</button>
                            {/* <button type="submit"  disabled={data?.session_type==='1' && data?.session_offer_type === '1' && selectedSlotIds?.length === 0} className={`choose ${(data?.session_type==='1' && data?.session_offer_type === '1' && selectedSlotIds?.length === 0) ? 'disabled' : ''}`}>Complete Bookingdfffffs</button> */}
                          </div>
                        )}


                        {data?.package_type === "4" && (
                          <div>
                            <div className='subtotal'>
                              <p>
                                <strong>Subtotal Price:</strong> <b>{data?.session_type==='1' ? (slotDisplay?.length>0 ? '$'+(subtotal || '-'):'$ 0.00'): ('$'+(subtotal || '-'))}</b>
                                {/* <strong>Subtotal Price</strong> <b>${subtotal.toFixed(2) || '-'}</b> */}
                              </p>
                              <button type="submit" disabled={data?.session_type === '1' && slotDisplay?.length === 0 || (data?.pricing_type === '2' && inputVariablePrice === 0)} className={`choose ${(data?.session_type === '1' && slotDisplay?.length === 0 || (data?.pricing_type === '2' && inputVariablePrice === 0)) ? 'disabled' : ''}`}>Complete Bookings</button>
                              {/* <button type="submit" disabled={selectedSlotIds?.length === 0} className={`choose ${selectedSlotIds?.length === 0 ? 'disabled' : ''}`}>Complete Booking</button> */}
                            </div>
                          </div>
                        )}
                      </>
                    )
                      : data?.session_type === "1" && data?.session_offer_type === "1" ? (
                        <div>
                          <div className='subtotal'>
                            <p>
                              <strong>Subtotal Price</strong> <b>{slotDisplay?.length>0 ? '$'+(subtotal || '-'):'$ 0.00'}</b>
                            </p>
                            <button type="submit" disabled={slotDisplay?.length === 0 || (data?.pricing_type === '2' && inputVariablePrice !== 0)} className={`choose ${(slotDisplay?.length === 0 || (data?.pricing_type === '2' && inputVariablePrice == 0)) ? 'disabled' : ''}`}>Complete Booking</button>
                            {/* <button type="submit" disabled={selectedSlotIds?.length === 0} className={`choose ${selectedSlotIds?.length === 0 ? 'disabled' : ''}`}>Complete Booking</button> */}
                          </div>
                        </div>
                      ) : data?.session_type === "2" && data?.session_offer_type === "2" ? (
                        <div>
                          <div className='subtotal'>
                            <p>
                              <strong>Subtotal Price</strong> <b>${data?.price || data?.minimum_price || data?.maximum_price || '-'}</b>
                            </p>
                            <button type="submit" className={`choose ${(data?.pricing_type === '2' && inputVariablePrice == 0) ? 'disabled' : ''}`} disabled={(data?.pricing_type === '2' && inputVariablePrice == 0)}>Complete Booking</button>
                          </div>
                        </div>
                      )
                        : data?.session_type === "2" && data?.session_offer_type === "1" ? (
                          <div>
                            <div className='subtotal'>
                              <p>
                                <strong>Subtotal Price</strong> <b>${data?.price || data?.minimum_price || data?.maximum_price || '-'}</b>
                              </p>
                              <button type="submit" className={`choose ${(data?.pricing_type === '2' && inputVariablePrice === 0) ? 'disabled' : ''}`} disabled={(data?.pricing_type === '2' && inputVariablePrice == 0)}>Complete Booking</button>
                            </div>
                          </div>
                        ) : null}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default BookingModal;
