import { useNavigate } from "react-router-dom";
import { getApi, postApi } from "../../../Services/apiService";
import { useEffect, useState } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripePromise } from '../../../Config/stripeService';  // Import stripePromise from the service
import graycross from '../../../assets/images/gray-cross.svg'
import Modal from 'react-modal';
import PaymentModal from "../../UserComponents/UserDiscovery/payment";


function BasicsBilling({  }) {

    const navigate = useNavigate()
    const [userData,setUserData] = useState({})
    const [loader,setLoader] = useState(false)
    const [userCards,setUserCards] = useState([])
     const [filtersModal, setFiltersModal] = useState(false);
     const [successModal, setSuccessModal] = useState(false);
     const [modalOpen, setModalOpen] = useState(false);
     const [connectorAccountData,setConnectorAccountData]= useState('')
  const [type,setType]=useState('')
    const user_roles = localStorage.getItem('user_role')
    console.log('this is user role',user_roles);
    useEffect(()=>{
        if(user_roles!=='user'){
        handleFetchDetail()
        }else{
            handleGetAllCards()
        }
    },[])
    console.log('setSuccessModal',successModal);
    useEffect(()=>{
        if(successModal === true){
            handleGetAllCards()
        }
    },[successModal])

    const handleFetchDetail =async() =>{
        const response = await getApi('/payment/checkAccountOnboarding')
        if(response?.statusCode === 200){
            setUserData(response?.data)
        }
    }
    console.log('filtersModalfiltersModal',filtersModal);
    console.log('loaderloaderloader',loader);

    const handleCheckout = () => {
          setType('billing')
          setFiltersModal(true)
       
      };
    const handleGetAllCards =async() =>{
        const response = await getApi('/payment/cards')
        if(response?.statusCode === 200){
            setUserCards(response?.data)
            setSuccessModal(false)
        }
    }
    const handlePay = async() =>{
        setLoader(true)
        const response = await postApi('/payment/addconnector_account','')
        if(response?.statusCode == 200){
            setLoader(false)
            setConnectorAccountData(response?.data)
            setModalOpen(true)
            // window.location.href = response?.data;
            // https://connect.stripe.com/setup/e/acct_1QAsvLP43GUDNHnF/Gul8uNB7LUjI    
        }
    }

    const handleSave = () =>{
        window.location.href = connectorAccountData;
    }
   
    return (
        
        <div class="billing">
        {user_roles !== 'user' &&
        <>
        <div class="section first">
            <h1>Billing schedule</h1>
            <p>Manage your billing and payouts here. Set up your payment preferences and manage invoicing. For example, if a member joins on 10th, they’ll be charged on the day, and then monthly on the 10th.</p>
        </div>

        <div class="section second">
            <div class="top">
            <h1>Pause upcoming billing cycle</h1>
            <p>Take a break from billing. Pause your upcoming billing cycle when you're not hosting sessions."</p>
        </div>
        <div class="bottom">
        <button class="pause-billing">Pause billing</button>
        </div>
    </div>
        

    <div class="section third">
        <div class="top">
        <h1>Payout method</h1>
        <p>Add a payout method to get paid!</p>
    </div>
    <div class="bottom">
        {userData?.isComplete == '1' ?
    <button class="payment-method" >Connected</button>
    :
    <button class="payment-method" onClick={()=>handlePay()} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Add payout method</button>
}
    </div>
</div>
</>
}

{user_roles === 'user' && (
    <>
    <div class="section fourth">
    <div class="top">
    <h1>{user_roles === 'user' ? 'Payment Methods' : 'Payout method'}</h1>
    <p>Add a payment method to pay for sessions and products!</p>
</div>
<div className="middle">
                    {userCards?.length > 0 && userCards.map((x, index) => (
                        
                        <div key={index} className="image-content d-flex justify-content-between align-items-center">
                            <div className="payment-content">
                                <h5>{x?.brand} ****{x?.last4}</h5>
                                <h6>Expires {x?.exp_month}/{x?.exp_year}</h6>
                            </div>
                            <div className="payment-buttons">
                                <button className="edit">Edit</button>
                                <button className="delete">Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
<div class="bottom">
<button class="payment-method" onClick={()=>handleCheckout()}>Add new payment method</button>
</div>


</div>
    </>
)}


{/* After billing payout  */}
{/* {user_roles !== 'user' &&
<main class="business-status">
    <div class="section first">
        <h1 class="business">Business status</h1>
        <div class="radio-container">
            <h1>How are you set up?</h1>
            <label class="radio-label" for="setup-individual">
                I am an individual
                <input type="radio" id="setup-individual" name="businessSetup" checked />
                <span class="checkmark"></span>
            </label>
            <label class="radio-label" for="setup-corporation">
                I represent a corporation
                <input type="radio" id="setup-corporation" name="businessSetup" />
                <span class="checkmark"></span>
            </label>
        </div>

        <div class="radio-container">
            <h1>What’s your citizenship status?</h1>
            <label class="radio-label" for="citizen-us">
                I am a US citizen or resident
                <input type="radio" id="citizen-us" name="citizenshipStatus" checked />
                <span class="checkmark"></span>
            </label>
            <label class="radio-label" for="citizen-not-us">
                I am <strong>not</strong> a US citizen or resident
                <input type="radio" id="citizen-not-us" name="citizenshipStatus" />
                <span class="checkmark"></span>
            </label>
        </div>
    </div>

    <div class="footer-button">
        <button class="cancel">Cancel</button>
        <button class="save">Save</button>
    </div>

    <div class="section second">
        <label for="country">Country</label>
        <select id="country">
            <option value="">Select your payout country</option>
        </select>
    </div>

    <div class="footer-button">
        <button class="cancel">Cancel</button>
        <button class="save">Save</button>
    </div>

    <div class="section third">
        <label for="country-detailed">Payout Country</label>
        <select id="country-detailed">
            <option value="afghanistan">Afghanistan</option>
            <option value="albania">Albania</option>
            <option value="algeria">Algeria</option>
        </select>
    </div>

    <div class="footer-button">
        <button class="cancel">Cancel</button>
        <button class="save">Save</button>
    </div>

    <div class="section fourth">
        <h1>How would you like to get paid?</h1>
        <label class="radio-label" for="payment-stripe">
            Direct Deposit via Stripe
            <input type="radio" id="payment-stripe" name="paymentMethod" checked />
            <span class="checkmark"></span>
        </label>
        <div class="payout-fee">
            <h4>Payout fee is USD $0.25 per payout <span>Learn more</span></h4>
        </div>

        <form>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="first-name">First Name</label>
                        <input type="text" id="first-name" class="form-control" placeholder="Type your name" />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="second-name">Second Name</label>
                        <input type="text" id="second-name" class="form-control" placeholder="Type your surname" />
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="address-1">Street address 1</label>
                        <input type="text" id="address-1" class="form-control" placeholder="Type your address" />
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="address-2">Street address 2</label>
                        <input type="text" id="address-2" class="form-control" placeholder="Type your address" />
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="city">City</label>
                        <input type="text" id="city" class="form-control" placeholder="Type your city" />
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="country">Country</label>
                        <input type="text" id="country" class="form-control" placeholder="Type your country" />
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="postcode">Postcode</label>
                        <input type="text" id="postcode" class="form-control" placeholder="Type your postcode" />
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="ssn">Individual SSN</label>
                        <input type="text" id="ssn" class="form-control" placeholder="Type your SSN" />
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="sort-code">Bank Sort Code</label>
                        <input type="text" id="sort-code" class="form-control" placeholder="Type your bank sort code" />
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="bank-account">Bank Account Number</label>
                        <input type="text" id="bank-account" class="form-control" placeholder="Type your bank account number" />
                    </div>
                </div>
            </div>
        </form>

        <label class="radio-label" for="payment-paypal">
            PayPal
            <input type="radio" id="payment-paypal" name="paymentMethod" />
            <span class="checkmark"></span>
        </label>
        <div class="payout-fee">
            <h4>Payout fee is 1% of the amount transferred, with a minimum of USD $0.25 and a maximum of USD $20 <span>Learn more</span></h4>
        </div>
    </div>


    <div class="footer-button">
        <button class="cancel">Cancel</button>
        <button class="save">Save</button>
    </div>

</main>
} */}
    <Modal
          isOpen={filtersModal} onRequestClose={() => setFiltersModal(false)} contentLabel="Payment Method"
          overlayClassName={`modal-overlay checkout-pay digital without-stripe`}
          portalClassName="checkout">
          <button className='close-button' onClick={() => setFiltersModal(false)}><img src={graycross} alt='' /></button>
          {/* <Elements stripe={stripePromise}> */}
          <PaymentModal setFiltersModal={setFiltersModal} setSuccessModal={setSuccessModal} type ={'billing'} itemDetails={''}></PaymentModal>
          {/* </Elements> */}

        </Modal>
    <Modal
          isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} contentLabel="Payment Method"
          overlayClassName={`modal-overlay checkout-pay digital without-stripe`}
          portalClassName="checkout add-seting-payment ">
          <button className='close-button' onClick={() => setModalOpen(false)}><img src={graycross} alt='' /></button>
         <p>To complete your account setup, please verify your details by clicking the link we've sent to your email. Once verified, your payout method will be successfully created, and you can start receiving payments.</p>
         <div className="modal-buttons text-center mt-3 pt-3">
         <button onClick={()=>setModalOpen(false)} className="cancel-btn">Cancel</button>
         <button onClick={()=>handleSave()} className="save-btn">Save</button>
         </div>
        </Modal>
    </div>
    );
}

export default BasicsBilling;